.order-history-filter {
  display: flex;
}
.order-history-filter .ant-input-group-addon:first-child {
  border-radius: 22px 0px 0px 22px;
  border: 1px solid #f2f2f2;
}

.order-history-filter input.ant-input {
  width: 400px;
  height: 48px !important;
  background: #f6f6f6;
  border: none;
  border-radius: 0px 22px 22px 0px;
}

.order-history-filter input:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.order-history-filter input::placeholder {
  color: #808080;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
