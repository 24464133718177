.images-banner-tmpl{
  gap: calc(var(--style-image-banner-gap, 16) * 1px);
  padding: calc(var(--style-image-banner-gap, 1rem) * 1px);
  background: var(--style-banner-background, #ffffff);

  @apply
    tw-flex;

  .image-content {
    @apply 
      tw-flex-1;
    
    img {
      border-radius: calc(var(--style-image-banner-radius, 4) * 1px);

      @apply
        tw-object-cover
        tw-w-full
        tw-h-full;
    }
  }

  @media (max-width: 768px) {
    overflow-x: scroll;
    overflow-y: hidden;

    .image-content{
      min-width: 200px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
