/*------ 25. My account Page  ------*/
.center {
  display: flex;
  justify-content: center;
}
.single-my-account {
  margin-bottom: 20px;
  border: 1px solid #ebebeb;

  .panel-heading {
    padding: 0;

    &>button {
      background: none;
      border: none;
      width: 100%;
      text-align: left;
      padding: 0;
    }
  }

  .card-body {
    border-bottom: 1px solid #ebebeb;
  }

  h3.panel-title {
    background-color: #f9f9f9;
    border-bottom: 1px solid #ebebeb;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
    color: #242424;
    display: block;
    padding: 16px 55px;
    position: relative;

    @media #{$xs-layout} {
      line-height: 22px;
      font-size: 14px;
      padding: 16px 30px 16px 40px;
    }

    &:hover {
      color: $theme-color;
    }

    &::before {
      color: #000;
      content: "\f107";
      display: inline-block;
      font-family: "FontAwesome";
      font-size: 16px;
      position: absolute;
      right: 10px;
      top: 19px;
    }

    &:hover::before {
      color: $theme-color;
    }

    span {
      color: #242424;
      font-size: 15px;
      left: 20px;
      position: absolute;
      top: 16px;
    }
  }

  .myaccount-info-wrapper {
    padding: 30px 20px;
    background-color: #fff;

    .account-info-wrapper {
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 28px;
      padding-bottom: 30px;

      h4 {
        font-size: 15px;
        margin: 0;
        text-transform: uppercase;
      }

      h5 {
        font-size: 16px;
        letter-spacing: 0.2px;
        margin-top: 7px;
      }
    }

    .billing-info {
      margin-bottom: 20px;

      label {
        color: #000;
        font-size: 14px;
        text-transform: capitalize;
      }

      input {
        background: transparent none repeat scroll 0 0;
        border: 1px solid #ebebeb;
        color: #000;
        height: 40px;
        padding: 0 15px;
      }
    }

    .billing-back-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 26px;
      align-items: center;

      .billing-back {
        a {
          color: #000;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;

          &:hover {
            color: $theme-color;
          }

          i {
            font-size: 16px;
            color: $theme-color;
          }
        }
      }

      .billing-btn {
        button {
          background-color: #f2f2f2;
          border: medium none;
          color: #000;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          padding: 16px 35px 17px;
          text-transform: uppercase;
          cursor: pointer;
          transition: all 0.3s ease 0s;

          &:hover {
            background: $theme-color;
            color: #fff;
          }
        }
      }
    }

    .entries-wrapper {
      border: 1px solid #eaeaea;
      position: relative;

      @media #{$xs-layout} {
        padding: 30px 10px;

        &::before {
          display: none;
        }
      }

      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 1px;
        left: 50%;
        top: 0;
        background-color: #eaeaea;
      }

      .entries-info {
        padding: 30px 20px;

        @media #{$xs-layout} {
          padding: 0 10px 30px;
        }

        p {
          color: #000;
          font-size: 15px;
          margin: 0;
          text-transform: capitalize;
        }
      }

      .entries-edit-delete {

        a,
        button {
          background: none;
          border: none;
          background-color: #000;
          color: #fff;
          display: inline-block;
          line-height: 1;
          margin: 0 2px;
          padding: 12px 15px;
          text-transform: uppercase;
          font-weight: 500;

          &:hover {
            background-color: $theme-color;
          }

          &.edit {
            background-color: #df5c39;

            &:hover {
              background-color: $theme-color;
            }
          }
        }
      }
    }
  }
}

// .disableField {
//   background-color: #F0F0F0 !important;
//   cursor: not-allowed !important;
// }

// .contactUs-text {
//   display: flex;
//   justify-content: center;
// }

// .contactUs-text h4 {
//   font-size: 16px;
// }
.credit-debit-amount {
  width: 25% !important;
}
.customerEmail {
  background-color: lightgrey !important;
}

// signup page styling

.multi-step-form-container {
  display: flex;
  height: 100vh;
}

.steps-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 140px 125px 35px 125px;
  flex: 0 0 617px;
  background-color: #1a1a1a;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

// .steps-container-item{
//   padding: 140px 125px 35px 125px;
//   flex: 0 0 633px;
// }
.cancel-btn-modal {
  height: 44px;
  border-radius: 50px;
  width: 193px;
  background: #F2F2F2;
  color: #333333;
}
.order-cancel-btn-modal {
  height: 44px;
  border-radius: 50px;
  width: 193px;
  background: #F2F2F2;
}

.order-cancel-btn-modal .btn-label {
  color: black;
}
.logo {
  display: flex;
  margin: auto;
  max-height: 78px;
  max-width: 210px;
  margin-bottom: 120px;
}

.steps-content {
  flex-grow: 1;
  overflow-y: auto;
}

.steps-container .ant-steps-item-title {
  color: var(--white-dark-white, #fff) !important;
  font-family: Montserrat !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.steps-container .ant-steps-item-icon {
  width: 46px;
  height: 46px;
  margin-right: 20px !important;
  border-radius: 9px;
  background: var(--style-primary-color, --primary-900) !important;
  border: none !important;
}

.steps-container .ant-steps-item-wait .ant-steps-item-icon {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px dashed rgba(255, 255, 255, 0.3) !important;
}

.steps-container .ant-steps-icon {
  color: #fff !important;
  top: 4.5px !important;
}

.have-account {
  float: right;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-top: 40px;
  padding-right: 70px;
}

.have-account .signIn {
  color: var(--style-primary-color);
  cursor: pointer;
}

.address-feild {
  width: 560px;
  margin-top: 8px;
}

.steps-container .ant-steps-item-content {
  min-height: 100px !important;
}

.ant-steps-item-tail {
  left: 23px !important;
  padding: 48px 0 2px !important;
  // border-style: dashed !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  border: 1px dashed var(--style-primary-color) !important;
}

.ant-steps-item-tail::after {
  border: 1px dashed rgba(255, 255, 255, 0.3);
  background: none !important;
}

.foot-info {
  display: flex;
  gap: 35px;
}

.foot-info span {
  color: var(--style-primary-color);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.text-danger {
  color: #ea1e51;
}

.page-route {
  cursor: pointer;
}

.customer-information-form {
  padding: 100px 70px;
}

.form-section h2 {
  /* Styles for the section header */
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-item {
  /* Styles for form item alignment and width */
}

.btns {
  display: flex;
  float: right;
  margin-right: 16px;
}

.next-button {
  border: none;
  color: white;
  display: flex;
  width: 180px;
  height: 52px;
  margin-top: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 32px;
  background: var(--style-primary-color);
}

.back-button {
  border: none;
  color: black;
  display: flex;
  margin-top: 44px;
  width: 180px;
  height: 52px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 32px;
  background: var(--gray-100, #e6e6e6);
}

.checkout-back-button {
  border: none;
  color: black;
  display: flex;
  width: 100px;
  height: 33px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 32px;
  background: var(--gray-100, #e6e6e6);
}

.remove-button {
  border: none;
  color: var(--style-primary-color);
  display: flex;
  margin-top: 37px;
  width: 180px;
  height: 52px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 32px;
  background: var(--style-light-primary-color);
}

.customized-row {
  gap: 16px;
  flex-wrap: wrap;
  display: flex;
}

.customized-input {
  border-radius: 64px;
  background: #f7f7f7;
  height: 52px !important;
  margin-top: 8px;
  margin-bottom: 5px;
  border: none !important;
  padding: 16px 24px !important;
}
.customized-input-value {
  border-radius: 64px;
  background: #f7f7f7;
  height: 52px !important;
  margin-top: 8px;
  margin-bottom: 5px;
  border: none !important;
  padding: 16px 24px !important;
  width: 100%;
}
.customize-certificate-value{
  display: flex;
  justify-content: space-between;
}
.file-input {
  border-radius: 64px;
  background: #f7f7f7;
  height: 52px !important;
  margin-top: 8px;
  margin-bottom: 5px;
  border: 2px dashed #666;
  // padding: 16px 24px !important
}

.customized-row .input-feilds {
  width: 368px;
  margin-top: 8px;
}

.customized-input-value:focus {
  background: #f7f7f7;
  border: 0.5px solid #999999 !important;
}
.customized-input:focus {
  background: #f7f7f7;
  border: 0.5px solid #999999 !important;
}

.customized-input-value:hover {
  background: #f7f7f7;
  border: 0.5px solid #999999 !important;
  color: #000;
}
.customized-input:hover {
  background: #f7f7f7;
  border: 0.5px solid #999999 !important;
}

.customized-row.input-feilds label {
  color: var(--gray-900, #1a1a1a);
  font-family: Montserrat !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.customized-input::placeholder {
  color: var(--gray-500, #808080);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.license-display-flex {
  display: flex;
}
.form .form-title {
  color: var(--style-primary-color);
  margin: 24px 0px;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.form-section h2 {
  margin-bottom: 44px;
}

.asterisk {
  color: #fb1100;
  margin-left: 2px;
}

.custom-phone-input .flag-dropdown {
  left: 12px !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

.custom-phone-input_2 .flag-dropdown {
  left: 12px !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

.custom-phone-input_5 .flag-dropdown {
  left: 12px !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  margin-top: 20px !important;
}

.collapse_panel {
  color: var(--style-primary-color);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.collapse_panel_2 {
  background-color: white;
  width: 1780px;
  padding: 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
}

.collapse_panel_acc_info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.collapse_field_acc_info_1 {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 20px;
}

.radio-custom-style {
  display: flex;
  justify-content: space-between;
}

.radio-custom-style .ant-radio-inner {
  width: 24px;
  height: 24px;
  border-radius: 7px;
}

.collapse_field_acc_info_2 {
  width: 100%;
}

.collapse_field_acc_info_3 {
  display: flex;
  height: 120px;
  justify-content: end;
}

.collapse_field_acc_info_4 {
  color: #808080;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 6px;
}

.collapse_field_acc_info_5 {
  width: 550px;
  // margin-left: 20px;
}

.collapse_field_info_start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.collapse_field_info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.collapse_date {
  display: flex;
  width: 177px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.collapse_tax {
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.collapse_state {
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.collapse_city {
  display: flex;
  width: 195px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.collapse_address {
  display: flex;
  width: 360px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.collapse_address_2 {
  color: #1a1a1a;
  text-align: left;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
}

.collapse_button {
  display: flex;
  width: 261px;
  height: 52px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 32px;
  background-color: var(--style-primary-color);
  color: white;
  margin-top: 30px;
}

.collapse_button_download {
  display: flex;
  width: 90px;
  height: 27px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 32px;
  background-color: var(--style-primary-color);
  color: white;
}

.collapse_panel_detail {
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.collapse_data_heading {
  color: #808080;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.collapse_data_detail {
  color: #1a1a1a;
  text-align: left;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1px;
}

.collapse_data_detail_2 {
  display: flex;
  width: 183px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.collapse_data_detail_3 {
  display: flex;
  width: 204px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.select-field {
  padding: 13px 24px;
  border-radius: 64px;
  height: 52px;
  font-size: 14px;
  margin-top: 8px;
  border: none;
  background: #f7f7f7;
}

.select-filter-field>.ant-select-selector {
  padding-top: 4px !important;
  border-radius: 32px !important;
  height: 36px !important;
  font-size: 14px !important;
  margin-top: 8px !important;
  border: none !important;
  background: #f7f7f7 !important;
}

.select-filter-field>.ant-select-arrow {
  top: 59% !important;
  color: #000000;
}

.DateTime-filter-field>.ant-picker {
  padding-top: 4px !important;
  border-radius: 32px !important;
  height: 36px !important;
  font-size: 14px !important;
  margin-top: 8px !important;
  border: none !important;
  background: #f7f7f7 !important;
}

.filters-title {
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.custom-phone-input .selected-flag {
  margin-top: 4px !important;
  margin-left: 10px !important;
}

.form {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #7a7a7a #f7f7f7;
  /* For Firefox: thumb and track color */
}

/* For Webkit browsers like Chrome and Safari */
.form::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

.form::-webkit-scrollbar-track {
  background: #f7f7f7;
  /* Background of the scrollbar track */
  border: 1px solid #b3b3b3;
  // background-color: #F2F2F2;
  border-radius: 50px;
}

.form::-webkit-scrollbar-thumb {
  border-radius: 100px;
  height: 150px;
  background: #7a7a7a;
  // border:1px solid #B3B3B3;/* Creates padding around the scrollbar thumb */
}

.selector-field .ant-select-selector {
  background: #f7f7f7 !important;
  margin-top: 8px !important;
  border-radius: 64px !important;
  border: none !important;
  height: 52px !important;
  // width: 352px !important;
  padding: 13px 24px !important;
}

.ant-select {
  width: 100%;
}

.selector-field .ant-select-arrow {
  right: 16px !important;
  top: 58% !important;
}

.yes-no-feild {
  margin-top: 8px;
}

input[type="checkbox"]:checked+label::before {
  background-color: #00ff00 !important;
  /* Background color for checked state */
  /* You can add more styling here, like a checkmark icon, etc. */
}

.add-licsense-btn {
  display: flex;
  width: 160px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: none;
  border-radius: 30px;
  background: #efedf8;
  color: var(--secondary-900, #5f4bb6);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.start-shop-btn {
  border-radius: 32px;
  background: var(--style-primary-color);
  width: 261px;
  height: 52px;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  color: var(--White, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.ThankuPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.ThankYouPage-Heading {
  color: var(--gray-900, #1a1a1a);
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.ThankuPage p {
  color: var(--gray-500, #808080);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 2% 10% 2% 10%;
}

.copyright-text {
  color: var(--gray-500, #808080);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 8px;
}

.checkbox-container:hover input~.checkmark {
  background-color: #f7f7f7;
}

.checkbox-container input:checked~.checkmark {
  border-radius: 7px;
  background: var(--style-primary-color);
  border: none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked~.checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 8px;
  top: 3px;
  width: 9px;
  height: 16px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.terms-policy {
  margin-top: 24px;
}

.text-link {
  color: var(--style-primary-color);
  text-decoration: none;
}

.text-link:hover {
  text-decoration: underline;
  color: var(--style-primary-color);
}

/* Override the border styles for the Ant Select when it is focused */
.ant-select-focused .ant-select-selector,
.ant-select-open .ant-select-selector {
  border-color: grey !important;
  border-width: 1px !important;
  box-shadow: none !important;
  /* Remove if you don't want the shadow */
}

// /* You might also want to style the hover state */
// .ant-select:hover .ant-select-selector {
//   border-color: grey !important;
// }

// /* If you want to apply the grey border by default */
// .ant-select .ant-select-selector {
//   border-color: grey !important;
// }

// login page styles

.login-page-container {
  display: flex;
  height: 100vh;
}

.login-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // flex: 0 0 750px;
  width: 40%;
  background-color: #1a1a1a;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.side-content {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.login-container {
  // padding: 0px 338px;
  width: 60%;
}

.forgot-password-container {
  padding: 0px 165px;
  width: 100%;
}

.login-container h1 {
  color: var(--gray-900, #1a1a1a);
  margin-bottom: 44px;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.container-btn {
  text-align: right;
  float: right;
}

.email-field .ant-input {
  border-radius: 64px;
  height: 52px !important;
  padding: 9px 18px;
}

.pwd-field .ant-input-affix-wrapper {
  border-radius: 64px;
  height: 52px !important;
  padding: 9px 18px;
  // padding-bottom: 13px;
}

.login-btn {
  border-radius: 32px;
  background: var(--style-primary-color);
  width: 180px;
  height: 52px;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 8px;
  color: var(--White, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.forgot-pwd {
  color: var(--gray-900, #1a1a1a);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.login-Forgot>.ant-row {
  padding: 0px;
  margin: 0px;
}

.login-have-account {
  position: absolute;
  right: 100px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-top: 40px;
}

.login-have-account .signup {
  color: var(--style-primary-color);
  cursor: pointer;
}

.login-field-labels {
  color: var(--gray-900, #1a1a1a);
  font-family: Montserrat;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.loginpage-logo {
  width: 425px;
  height: 200px;
  overflow: hidden;
  text-align: center;
}

.sidebar-logo {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

.customized-Antinput {
  border-radius: 64px;
  background: #f7f7f7;
  height: 52px !important;
  width: 100% !important;
  margin-top: 8px;
  // margin-bottom: 5px;
  border: none !important;
  padding: 10px 24px !important;
}

.cursor {
  cursor: not-allowed;
}

.customized-Antinput .ant-input {
  background: none !important;
}

.email-field .ant-input:focus,
.ant-input-focused {
  border: 0.5px solid #999999 !important;
  box-shadow: none !important;
}

.email-field .ant-input:hover {
  border: 0.5px solid #999999 !important;
  box-shadow: none !important;
}

.pwd-field .ant-input-affix-wrapper-focused {
  border: 0.5px solid #999999 !important;
  box-shadow: none !important;
}

.pwd-field .ant-input-affix-wrapper:hover {
  border: 0.5px solid #999999 !important;
}

.ant-field-label {
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

// ledger styling

.main-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tabs-container {
  width: 100%;
  background: #fff;
  padding: 0px 60px;
}

.wrapper-container {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 8px;
  margin-bottom: 70px;
  background: #fff;
  box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
  padding: 0px 20px;
}

.main-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list {
  margin: 24px 0px;
  border-radius: 15px;
  padding: 8px 16px;
  border: 1px solid var(--gray-50, #f2f2f2);
  background: var(--White, #fff);
  width: 498px;
  height: 68px;
  flex-shrink: 0;
}

.main-tabs>.ant-tabs-nav .ant-tabs-tab {
  padding: 20px;
}

.main-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list .ant-tabs-tab-active {
  background: var(--style-light-primary-color);
}

.main-tabs .ant-tabs-tab-btn {
  color: #b3b3b3;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.main-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-active>.ant-tabs-tab-btn {
  color: var(--style-primary-color) !important;
}

.main-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-ink-bar {
  display: none;
}

.Statements-wrapper {
  padding: 20px 40px;
}

.statement-tabs .ant-tabs-tab-btn {
  color: #b3b3b3;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.statement-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-active>.ant-tabs-tab-btn {
  color: var(--style-primary-color) !important;
}

.statement-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list .ant-tabs-tab-active {
  background: var(--style-light-primary-color);
}

.statement-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-btn {
  color: #b3b3b3;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.statement-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-ink-bar {
  background: var(--style-primary-color);
}

.statement-tabs>.ant-tabs-nav .ant-tabs-tab {
  padding: 12px;
}

.ant-custom-table .ant-table-thead>tr>th {
  background: none;
  border-color: #b3b3b3;
  color: var(--gray-500, #808080);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.ant-custom-table .ant-table-tbody>tr>td {
  padding: 12px 16px;
  border-color: #b3b3b3;
  overflow: hidden;
  color: #1a1a1a;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px;
}

.main-tabs>.ant-tabs-nav::before {
  border-bottom: none;
}

.statement-tabs .ant-tabs-nav::before {
  border-bottom: 1.5px solid #f2f2f2;
}

//  .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
//   border-bottom: none;
// }

.pagination-style {
  margin-top: 20px;
  float: right;
  display: flex;
    justify-content: center;
}

.center-text {
  display: flex;
  justify-content: center;
  width: 100%;
}

.cell-value-center {
  text-align: left;
  min-width: 80px;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  width: 54px;
  height: 54px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 28px;
  height: 75% !important;
  width: 75% !important;
}

.ant-pagination-item,
.ant-pagination-item a {
  border: none;
  color: var(--gray-500, #808080);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.ant-pagination-item a:hover,
.ant-pagination-item-active a:hover {
  color: none !important;
  background-color: transparent;
}

// .ant-pagination-item .ant-pagination-item-1 .ant-pagination-item-active{
//   border: none;
// }
.ant-pagination-item-active a {
  color: var(--gray-900, #1a1a1a);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 90% */
  // text-decoration-line: underline;
}

// .ant-popover-placement-bottom .ant-popover-arrow{
//   display: none;
// }

//  Actions drop down menu stylings
.ant-custom-dropdown .ant-dropdown-menu {
  border-radius: 6px !important;
  border: 1px solid #f1f1f2 !important;
  background: var(--white-dark-white, #fff) !important;
  box-shadow: 0px 8px 14px 0px rgba(15, 42, 81, 0.08) !important;
}

.menu-ul {
  display: flex;
  flex-direction: column;
  width: 139px;
  padding: 8px 0px;
}

.menu-li {
  padding: 12px 16px;
}

.menu-text {
  color: var(--gray-500, #808080);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

// order details styling

// .details-table{
// max-height: 246px;
// overflow: auto;
// }
.sale-return-status {
  width: 220px;
  margin: auto;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  color: white;
}
.modal-label {
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.ant-modal-footer {
  border: none;
}

.details-table table tr {
  border-bottom: 1px solid #b3b3b3;
}

.details-table td,
th {
  padding: 16px 0px;
}

.details-table th {
  color: var(--gray-500, #808080);
  min-width: 150px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.details-table td {
  overflow: hidden;
  color: var(--gray-900, #1a1a1a);
  // text-overflow: ellipsis;
  // white-space: nowrap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.details-table > .details-table td {
  line-height: 14px !important;
}

.details-table table th:not(:first-child),
.details-table table td:not(:first-child) {
  text-align: center;
}

.details-table {
  max-height: 450px;
  overflow-y: auto;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: black #f1f1f1; /* Firefox */
}

.details-table::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.details-table::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background */
}

.details-table::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb (the draggable part) */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 3px solid #f1f1f1; /* Padding around thumb */
}

.details-table::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color when hovered */
}

.order-detail-table th {
  // width: 25%;
  color: #808080;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.order-detail-table td {
  // width: 25%;
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.order-detail-tabs {
  margin-top: 25px;
}

.col-header {
  color: #808080;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.col-data {
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.payment-history-table-width {
  width: 25%;
}
.payment-history-table-width-two {
  width: 25%;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 32px;
  text-align: center;
  border-radius: 23px;
  background: #eaf4f0;
  color: #10b265;
}

.ant-table-column-title {
  margin-right: 16px;
}

.ant-table-column-sorters {
  display: inline-flex;
}

.ant-tooltip-inner {
  background-color: #1a1a1a;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: var(--style-primary-color);
}

.ant-popover-inner {
  border-radius: 8px;
  border: 1px solid var(--gray-100, #e6e6e6);
  background: #fff;
}

.ant-popover-arrow {
  display: none;
}

.ant-popover-content {
  right: 132px;
}

.btn-label {
  color: whitesmoke;
}

.export-btn .btn-label {
  padding-top: 10px;
}

.filters-text {
  padding-bottom: 12px;
  border-bottom: 2px solid #e6e6e6;
  color: var(--gray-900, #1a1a1a);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.filter-dropdown {
  height: auto;
  border-radius: 8px;
  border: 1px solid var(--gray-100, #e6e6e6);
  background: #fff;
  padding: 18px;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.filter-item {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;
}

.center {
  display: flex;
  justify-content: center;
}

//  Checkout Styling

.checkout-main-container {
  display: flex;
  gap: 16px;
  padding: 40px 70px 60px;
}

.checkout-wrapper {
  flex-direction: column;
  display: flex;
  width: 100%;
  // gap: 16px;
}

.cart-wrapper {
  // width: 564px;
  // height: 1212px;
  border-radius: 8px;
  background: #fff;
  padding: 16px;
  // box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
  border-radius: 8px;
  border: 1px solid var(--Gray-100, #E6E6E6); 
}

.customer-info-container {
  width: 100%;
  border-radius: 8px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid var(--Gray-100, #E6E6E6);
  /* Drop Shadow */
  // box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
  padding: 24px;
  margin-top: 1%;
  width: 98%;
}
.customer-info-container-two {
  width: 70%;
  border-radius: 8px;
  // background: #fff;

  /* Drop Shadow */
  // box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
  // padding: 24px;
}
.customer-info-container-three {
  width: 30%;
  border-radius: 8px;
  // background: #fff;

  /* Drop Shadow */
  // box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
  // padding: 24px;
}

.customer-info-wrapper {
  display: flex;
  justify-content: space-between;
}
.checkout-page-info {
  display: flex;
}
.customer-info-wrapper h3 {
  color: var(--style-primary-color);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.billing-title {
  color: #5f4bb6;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.customer-details {
  display: flex;
  justify-content: space-between;
}

.customer-info1 {
  width: 33.33%;
}

.customer-info1 h4 {
  color: #808080;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.customer-info1 p {
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.custom-radio-group .ant-radio-inner {
  border-color: var(--gray-900);
}

.custom-radio-group .ant-radio-inner::after {
  background-color: var(--style-primary-color);
}

.custom-radio-group .ant-radio:hover .ant-radio-inner,
.custom-radio-group .ant-radio-input:focus + .ant-radio-inner,
.custom-radio-group .ant-radio-checked .ant-radio-inner,
.custom-radio-group .ant-radio-checked .ant-radio-inner::after {
  border-color: var(--style-primary-color); 
}

.checkout-input-feilds {
  width: 335px;
  margin-top: 8px;
}

.customized-row1 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.address-feild1 {
  width: 510px;
  margin-top: 8px;
}

.textarea {
  border-radius: 8px;
  /* border: 1px solid #999; */
  background: #f7f7f7;
  width: 100%;
  height: 100px;
  margin-top: 8px;

  &:focus {
    // outline: none;
    border: 1px solid #999;
  }
}

.char-counter {
  float: right;
}
.checkout-page-msg {
  font-size: 11px;
  margin-top: 30px;
}

.payments-items {
  flex-direction: column;
  display: flex;
  gap: 16px;
}

.button-container {
  display: flex;
  margin-bottom: 4px;
  gap: 24px;
}

.custom-radio .ant-radio-checked .ant-radio-inner {
  border: 2px solid var(--style-primary-color);
}

.custom-radio .ant-radio-inner {
  width: 30px;
  height: 30px;
  border: 1px solid #cccccc;
}

.custom-radio .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--style-primary-color);
}

.ant-radio-wrapper {
  align-items: center;
}

.ant-radio-checked::after {
  border: 1px solid var(--style-primary-color);
}

.custom-radio .ant-radio-inner::after {
  width: 30px;
  height: 30px;
  top: 22%;
  left: 24%;
  background-color: var(--style-primary-color);
}

.Ach-info-container h3 {
  color: #5f4bb6;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 4px;
}

.ach-cancel-button {
  width: 193px !important;
  height: 44px !important;
  border-radius: 50px;
  background: #CCC;
  color: #333333
}

.ach-save-button {
  width: 193px !important;
  border-radius: 32px;
  height: 44px !important;
  background: var(--style-primary-color);
  color: #fff;
}

.ach-cancel-button {
  width: 133px;
  height: 35px;
  border-radius: 50px;
  background: #CCC;
  color: #333333
}

.ach-save-button {
  width: 133px;
  height: 35px;
  background: var(--style-primary-color);
  color: #fff;
}

.checkout-cart-table {
  max-height: 536px;
  overflow-y: auto;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #7a7a7a #f7f7f7;
  /* For Firefox: thumb and track color */
}

/* For Webkit browsers like Chrome and Safari */
.checkout-cart-table::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

.checkout-cart-table::-webkit-scrollbar-track {
  background: #f7f7f7;
  /* Background of the scrollbar track */
  border: 1px solid #b3b3b3;
  // background-color: #F2F2F2;
  border-radius: 50px;
}

.checkout-cart-table::-webkit-scrollbar-thumb {
  border-radius: 100px;
  height: 150px;
  background: #7a7a7a;
  border: 1px solid #b3b3b3;
  /* Creates padding around the scrollbar thumb */
}

/* Styles for the checkout-cart-table */
.checkout-cart-table table {
  width: 100%;
}

.checkout-cart-table tr {
  border-bottom: 1px solid #b3b3b3;
}

.checkout-cart-table th {
  color: #808080;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.checkout-cart-table td {
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 8px 0px;
}

.checkout-cart-table th,
.checkout-cart-table td {
  text-align: center;
  /* Center align for all cells */
}

.checkout-cart-table th:first-child,
.checkout-cart-table td:first-child {
  text-align: left;
  /* Left align for the first cell in each row */
}

.checkout-cart-info {
  margin-top: 70px;
}

.checkout-cart-items {
  justify-content: space-between;
  display: flex;
  margin-bottom: 32px;
}

.checkout-cart-items span {
  color: #808080;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.checkout-cart-items p {
  color: #1a1a1a;
  text-align: right;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.term_condition-text,
.term_condition-text:hover {
  color: var(--style-primary-color);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.invoice-total {
  align-items: center;
  padding: 8px 0px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.ThankYou-Wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 99px;
}

.ThankYou-Wrapper .Headingtext {
  color: #1a1a1a;
  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.96px;
}

.ThankYou-Wrapper .paraText {
  color: #999;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.ant-select-dropdown {
  z-index: 1053 !important;
}

.masknumbers {
  color: #1A1A1A;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;
}

.forms-labels {
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

// Writing this style to make display none for default lable of Input Field
.special-label {
  display: none;
}

.cart-para {
  color: #1a1a1a;
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin-top: 24px;
}

.cart-text {
  color: #808080;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
}

.custom-table-data {
  width: 25%;
}

.ant-dropdown-trigger .btn-label {
  color: #000;
}

.signature-div {
  display: flex;
  justify-content: flex-end;
}

.TCs-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F2F2F2;
  background: #FFF;
  padding: 8px;
}

.TCs-body {
  display: flex;
  padding-top: 24px;
}

.TCs-btns {
  display: flex;
  justify-content: space-between;
  // margin-top: 42px;
}

.ant-notification {
  z-index: 2000 !important;
}

.modal-label-name {
  color: #1A1A1A;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.order-details-modal-info {
  display:flex; 
  justify-content:space-between;
  padding: 0px 24px;
}
.order-details-modal-info-first {
  display:flex; 
  justify-content:space-between;
  padding: 0px 24px 0px 0px;
}
.order-details-modal-info-tax {
  display:flex; 
  justify-content:space-between;
  padding: 0px 45px;
}
.col-border-right {
  border-right: 1px solid #ccc;
}
.summary {
  color: var(--Primary-900, #EA1E51);
  padding: 0px 15px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.TCs-modal {
  //   display: flex;
  width: 678px;
  // flex-direction: column;
  // align-items: center;
  gap: 24px;
  border-radius: var(--dialog-radius, 16px);
  background: #FFF;
  box-shadow: -40px 40px 80px -8px rgba(0, 0, 0, 0.24);
}

.linked-text {
  cursor: pointer;
  text-decoration: underline;
  color: var(--style-primary-color);
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  font-family: Montserrat;
}

.linked-text:hover {
  color: var(--style-primary-color);
  text-decoration: underline;
}

.TCs-submit-btn {
  width: 93px;
  border-radius: 8px;
  height: 36px;
  background: var(--style-primary-color);
  color: white;
}

.TCs-submit-btn.ant-btn:hover {
  background: var(--style-primary-color);
  border-color: var(--style-primary-color);
  color: #FFF;
}

.TCs-auth-btn {
  width: 225px;
  border-radius: 8px;
  height: 36px;
  background: #F2F2F2;
  color: #333333;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.sign-title-text {
  font-family: Montserrat;
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.sign-date {
  color: #1A1A1A;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.date-cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sign-input {
  border-radius: 16px;
  background: #F7F7F7;
  height: 34px !important;
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bank-details-container {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
  padding: 24px 12px;
  margin-top: 12px;
  width: 100%;
}

.bank-details-container h3 {
  color: #5F4BB6;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.cursor-point {
  cursor: pointer;
}

.field-disabled {
  border-radius: 64px;
  cursor: not-allowed;
  background: #f7f7f7;
  height: 52px !important;
  margin-top: 8px;
  margin-bottom: 5px;
  border: none !important;
  padding: 16px 24px !important;
}

.field-disabled:hover {
  border: none;
}

.TCs-body>.ant-checkbox-wrapper>.ant-checkbox>.ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 7px;
}

.TCs-body>.ant-checkbox-wrapper>.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--style-primary-color);
  border-color: var(--style-primary-color);
}

.TCs-body>.ant-checkbox-wrapper>.ant-checkbox-checked .ant-checkbox-inner::after {
  left: 26%;
}

.my-accounts-bank-details {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
  padding: 24px 12px;
  margin-top: 12px;
  width: 40%;
}

.my-accounts-bank-details h3 {
  color: #5F4BB6;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.radio-group-bg {
  align-items: center;
  display: flex;
  width: 301px;
  height: 90px;
  margin: 24px 0px;
  padding-left: 12px;
  border-radius: 12px;
  border: 1px solid #F2F2F2;
  background: #F2F2F2;
}

.radio-text-font {
  color: #1A1A1A;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.ach-save-button.ant-btn:hover {
  color: #FFF;
  background-color: var(--style-primary-color);
  border-color: var(--style-primary-color);
}

.password_criteria {
  color: #808080;
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.criteria-container {
  display: flex;
  flex-direction: column;
}

.billing-checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.billing-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.billing-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 6px;
}

.billing-checkbox-container:hover input~.billing-checkmark {
  background-color: #f7f7f7;
}

.billing-checkbox-container input:checked~.billing-checkmark {
  border-radius: 6px;
  background: var(--style-primary-color);
  border: none;
}

.billing-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.billing-checkbox-container input:checked~.billing-checkmark:after {
  display: block;
}

.billing-checkbox-container .billing-checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (max-width: 850px) {
  .login-page-container {
    flex-direction: column;
  }

  .login-wrapper {
    flex: 0 0 300px;
  }

  .loginpage-logo {
    width: 280px;
  }

  .side-content {
    display: inline-table;
  }
}

// mobile responsivness of signup page
@media only screen and (max-width: 430px) {
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 28px;
    height: 60% !important;
    width: 60% !important;
  }
  .Ach-info-container .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100% !important;
    height: 52px !important;
    font-size: x-small !important;
    padding-right: 0px !important;
}
.Ach-info-container .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  font-size: 11px !important;
}
.payment-history-table-width {
  width: 155px;
}
.payment-history-table-width-two {
  width: 155px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bank-details-container {
  padding: 24px 0px;
}
  .main-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list {
    margin: 35px 0px;
  }
  // .react-tel-input .selected-flag {
  //   height: 50px;
  // }
  .ThankYouPage-Heading{
    font-size: 14px;
  }
  .thankyou-img {
    height: 200px;
    width: 300px;
  }
  .ThankuPage p {
    font-size: 12px;
    width: 430px;
  }
  .back-button {
    margin-top: 29px;
  }
  .credit-debit-amount {
    width: 100%;
  }
  .ant-pagination-item a{
    font-size: 14px;
  }
  .multistep-form {
    justify-content: center !important;
    max-width: 100%;
    margin: 0px 45px;
    flex-direction: row;
    overflow: hidden;
    flex-direction: row;
  }

  .step-title {
    // display: none !important;
    /* Adjust the styling of each step for horizontal layout if necessary */
  }
  // .pagination-style {
  //   max-width: 300px;
  // }
  .ant-steps-item-container {
    display: flex;
    flex-direction: column;
  }

  .ant-steps-item {
    flex: 1 1 !important;
    // flex: none !important;
  }

  .multi-step-form-container {
    display: block;
  }

  .steps-container .ant-steps-item-title {
    font-size: 10px !important;
  }

  .multistep-form .step-title {
    display: flex;
    /* Display inline with flexbox */
    position: relative;
    /* For pseudo-element positioning */
    /* Other styles... */
  }

  /* Pseudo-elements for tails */
  .multistep-form .ant-steps-item-tail::after {
    margin-left: 24px;
    border: 1px dashed rgba(255, 255, 255, 0.3);
    background: none !important;
    content: "";
    position: absolute;
    top: 31%;
    left: 23%;
    // width: 94px !important;
    width: 22vw !important;
    height: 1px !important;
    background-color: #ccc;
    transform: translateY(-50%);
  }

  .forgot-password-container {
    padding: 0px;
    width: 100%;
  }

  /* Remove the tail from the last step */
  .multistep-form .step-title:last-child::after {
    display: none;
  }

  .customer-info-container {
    margin-right: 0px;
  }

  .customer-information-form {
    padding: 16px 16px;
  }

  .customer-info-container .button-container .center button {
    width: 321px !important;
  }

  .customer-info-container {
    padding: 10px;
  }
  .pagination-style {
    margin-top: 20px;
    float: none;
    display: flex;
    justify-content: center;
  }
  .Ach-info-container {
    display: flex;
    flex-direction: column;
  }

  .ach-cancel-button {
    width: 130px;
    height: 35px;
    border-radius: 50px;
    background: #CCC;
    color: #333333
  }

  .ach-save-button {
    width: 130px;
    height: 35px;
    background: var(--style-primary-color);
    color: #fff;
  }

  .form {
    max-height: fit-content !important;
    overflow-y:unset
  }
  .checkout-input-feilds {
    width: 100%;
  }
  // .customized-row{
  //   width: 100vw !important;
  // }
  .customized-row .input-feilds {
    width: 100% !important;
  }

  .custom-phone-input .form-control {
    width: 100% !important;
  }

  .customized-Antinput {
    width: 100% !important;
  }

  .ant-steps-item-title {
    padding: 0px !important;
  }

  .steps-container {
    overflow: hidden !important;
    height: 250px;
    padding: 0px !important;
    background-image: none !important;
  }

  .steps-container .ant-steps-item-content {
    min-height: 10px !important
  }

  // .multistep-form .step-title{
  //   padding: 0px 26px;
  // }
  .multistep-form .step-title:nth-child(2) {
    padding-left: 0px !important;
    /* No left padding for the third step title */
  }

  .head-main {
    margin-top: auto;
  }

  .logo {
    margin: 8px auto;
    display: flex;
    justify-content: center;
  }

  .foot-info {
    display: none;
  }

  .form-section h2 {

    font-size: 18px;
    margin-bottom: auto;

  }

  .form .form-title {
    font-size: 16px;

  }

  .next-button {
    width: 100%;
    margin: 28px 0px;
  }

  .btns {
    width: 100%;
    margin: 0px;
  }

  .steps-content {
    position: relative;
  }

  .have-account {
    position: absolute;
    width: 100%;
    justify-content: center;
    display: flex;
    margin: auto;
    top: 100%;
    padding-right: 0;
    padding-bottom: 36px;
    padding-top: 0px;
  }

  .checkout-wrapper {
    flex-direction: column;
    display: flex;
    width: 100%;
    grid-gap: 16px;
    gap: 16px;
    margin-top: 25px;
  }

  .checkout-main-container {
    flex-direction: column;
    padding: 15px 16px 60px;
  }

  .customer-details {
    flex-direction: column;
  }

  .customer-info1 {
    width: 80%;
  }

  .button-container {
    display: inline;
  }

  .cart-wrapper {
    width: 100%;
    height: 100%;
  }
  .ant-field-label {
    margin-top: 20px !important;
  }

  .custom-phone-input_2 {
    // height: 40px !important;
    width: 100% !important;
  }

  .collapse_button {
    height: 32px;
    width: 133px;
    margin-top: 0px;
  }

  .wrapper-container {
    padding: 0px 0px;
  }

  .tabs-container {
    padding: 0px 0px !important;
  }

  .order-history-table-container {
    padding: "16px 16px 16px 16px" !important;
  }

  .ant-table {
    overflow-x: scroll;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
  }

  .print-btn {
    width: "100px" !important;
    padding: " 12px 16px";
  }

  .cancel-btn {
    width: "100px" !important;
  }

  .filter-button {
    width: 50px !important;
  }

  .filter-button-title {
    display: none !important;
  }

  .ant-popover-content {
    right: 10px !important;
    width: 300px;
  }

  .cancel-btn {
    width: "100px" !important;
  }

  .apply-btn {
    width: 100px !important;
  }

  .print-btn {
    width: 100px !important;
  }

  .print-btn-2 {
    display: none !important;
  }

  .cancel-btn {
    width: 100px !important;
  }

  .export-btn {
    // width: 100px !important;
    display: none !important;
  }

  .filter-btn-2 {
    width: 50px !important;
  }

  .filters-text {
    display: none !important;
  }

  .custom-table-data {
    width: 50% !important;
  }

  .custom-table-row {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-dropdown-trigger {
    width: 50px !important;
  }

  .ant-dropdown-trigger .filters-text {
    display: none !important;
  }
  .login-page-container {
    flex-direction: column;
    height: max-content;
  }

  .loginpage-logo {
    display: flex;
    align-items: center;
    max-width: 100%;
    justify-content: center;
  }

  .login-wrapper {
    overflow: hidden !important;
    flex: 250px !important;
    padding: 0px !important;
    background-image: none !important;
    width: 100% !important;
  }

  .login-container {
    padding: 0px !important;
    width: 100% !important;
    // text-align: center;
  }

  .login-container h1 {
    font-size: 28;
    text-align: center;
  }

  .side-content {
    height: auto;
  }

  .container-btn {
    width: 100%;
  }

  .login-btn {
    width: 100%;
    margin-top: 0px;
  }

  .login-have-account {
    width: 100%;
    justify-content: center;
    display: flex;
    margin: auto;
    top: 100%;
    right: 0;
    padding-top: 0px;
  }
  .my-accounts-bank-details {
    width: 100%;
  }
  .radio-group-bg {
    width: 100%;
    height: 60px;
  }
  .radio-text-font {
    font-size: 15px;
  }
  .masknumbers {
    font-size: 11px;
  }
  .sidebar-logo {
    height: 100px;
    width: 200px;
}
.checkout-page-info {
  display: flex;
  flex-direction: column;
}
.customer-info-container-two {
  width: 100%;
  border-radius: 8px;
}
.customer-info-container-three {
  width: 100%;
}
}

@media only screen and (max-width: 375px) {
  .multistep-form .ant-steps-item-tail::after {
    width: 21vw !important;
  }
}

@media only screen and (max-width: 360px) {
  .multistep-form .ant-steps-item-tail::after {
    width: 20vw !important;
  }

  .steps-container .ant-steps-item-title {
    font-size: 10px !important;
  }

}

