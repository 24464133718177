.row {
  display: flex;
}

.menu-toggle-icon {
  display: none;
}

.navbar {
  width: 100vw;
  justify-content: space-between;
  z-index: 10;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  display: flex;
  padding: 0 60px;
}

.headerLogo {
  height: 90px;
  position: relative;
  top: 20px;
}

.main-logo {
  max-width: 206px;
  height: 70px;
}

.icons-for-small-and-medium-devices {
  display: none;
}


.navLinks {
  margin-left: auto;
  margin-right: auto;
  
}

.primary-font *{
  font-family: var(--style-primary-font-family, "Montserrat", sans-serif);
}
.nav-bg *{
  background-color: var(--style-nav-background, #ffffff) !important;
}

.nav-text .ant-dropdown-menu-title-content{
  color: var(--style-nav-text-color) !important;
}
.nav-text .ant-dropdown-menu-title-content:hover{
  color: var(--style-nav-text-color-hover) !important;
}

.header-left-wrap-for-medium-devices {
  display: none;
  justify-content: flex-start;
  padding: 22px 0px;
}

.mobile-bottom-menu {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.navFooter {
  margin-right: 100px;
  position: relative;
}

.small-devices-search-bar {
  display: none;
}

.login-signup-button {
  margin-left: 4px;
  display: flex;
  width: 149px;
  height: 48px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  background: var(--style-primary-color);
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: none;
}

.home-search-products-list-container {
  left: 18px !important;
}

.home-search-products-list-container * {
  background-color: var(--style-nav-background, #ffffff) !important;
}
.home-search-products-list-container p {
  color: var(--style-nav-text-color);
}
.home-search-products-list-container span {
  color: var(--style-nav-text-color);
}

.header-searchbar {
  width: 308px;
  height: 40px;
  border-radius: 27px;
  background-color: transparent;
  border: unset;
}

.header-icon-group-menu {
  padding: 12px 16px;
  display: block;
  width: 170px;
  cursor: pointer;
}

.header-margin {
  margin-top: 35px !important;
  margin-bottom: 35px;
}

.sidebaricon {
  display: none;
}
.main-menu nav ul li:hover a,
.main-menu nav ul li a.active {
  color: var(--style-nav-text-color-hover) !important;
  font-family: var(--style-primary-font-family, "Montserrat", sans-serif);
  font-size: calc(var(--style-nav-font-size, 16) * 1.1px);
  font-style: normal;
  font-weight: 500;
}
.main-menu nav ul li:active a {
  color: #c51515 !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500;
}

.home-search-products-bar {
  display: flex;
  align-items: center;
  /* width: 330px; */
  height: 37px;
  border-radius: 27px;
  margin-right: 10px;
  border: 1px solid;
  border-color: #E6E6E6;
  /* background-color: #f2f2f2; */
  padding-left: 15px;
  margin-left: 20px;
}

.search-position {
  position: relative;
}
.search-icon {
  position: relative; 
  display: inline-block;
  cursor:pointer;
  height: 30px;
  width: 30px;
  background: var(--style-primary-color);
  border-radius: 50%;
}

.searchbar-menu {
  width: 330px;
  max-height: 400px;
  overflow: auto;
  padding-right: 3%;
}

.header-right-wrap div:hover {
  color: var(--style-nav-text-color-hover) !important;
}

@media (max-width: 1530px) {
  .home-search-products-bar {
    width: 270px;
  }

  .header-searchbar {
    width: 220px;
  }
}

@media (max-width: 1500px) {
  .home-search-products-bar {
    width: 270px;
  }

  .header-searchbar {
    width: 220px;
  }

}

@media (max-width: 1400px) {
  .home-search-products-bar {
    width: 240px;
  }

  .header-searchbar {
    width: 190px;
  }
}

@media (max-width: 1367px) {
  .sidebaricon {
    display: flex;
  }

  .main-menu {
    display: flex;
  }

  .menu-toggle-icon {
    display: block;
  }

  .navbar {
    justify-content: space-between;
  }

  .navLinks {
    display: none;
  }

  .main-menu nav ul {
    display: flex !important;
    /* margin-top: 30px !important; */
    text-align: left !important;
    flex-direction: column !important;
  }

  .main-menu nav ul li>a {
    line-height: 50px !important;
  }

  .hide-for-tablet-and-mobile {
    width: 100%;
    justify-content: space-around;
  }

  .home-search-products-bar {
    width: 280px;
  }

  .header-searchbar {
    width: 230px;
  }
}

@media (max-width: 1070px) {
  .header-right-wrap {
    display: none !important;
  }

  .header-left-wrap-for-medium-devices {
    display: flex !important;
  }
}

@media (max-width: 850px) {
  .icons-for-small-and-medium-devices {
    display: flex;
    margin-right: 20px;
  }

  .header-profile-dropdown-form-small-devices {
    right: 10px !important;
    left: auto !important;
    /* left: 20px; */
    max-width: 350px;
    /* left: 3px !important; */
    top: 108px !important;
  }

  .small-devices-search-bar {
    display: contents !important;
  }

  .home-search-products-list-container {
    width: 100%;
    min-width: none !important;
  }

  .searchbar-menu {
    width: 88% !important;
    padding: 10px 20px;
    right: 30px;
    margin: auto;
  }

  .header-margin {
    margin: 26px 0px 22px 0px !important;
  }

  .home-search-products-bar {
    width: 450px;
  }

  .header-searchbar {
    width: 400px;
  }

  .headerLogo {
    margin-left: 0px !important;
    /* position: relative; */
    position: static;
    height: 98px !important;
    margin: auto;
  }

  .sidebaricon {
    display: flex;
  }

  .navbar {
    width: 100vw;
    height: 98px;
    padding: 33px 38px 24px 38px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .hide-for-tablet-and-mobile {
    display: none !important;
  }

  .main-logo {
    width: 135px;
    height: 40px;
    margin-top: 0px;
  }

  .main-menu {
    display: flex;
  }

  .main-menu nav ul {
    display: flex !important;
    text-align: left !important;
    flex-direction: column !important;
  }

  .main-menu nav ul li>a {
    line-height: 50px !important;
  }

  .profile-header-logo,
  .cart-icon-logo {
    height: 40px;
  }

  .login-signup-button {
    margin-left: 40px;
    width: 116px;
    height: 30px;
    padding: 6px;
    font-size: 10px;
  }
}

@media (max-width: 500px) {
  .home-search-products-bar {
    width: 360px;
  }

  .header-searchbar {
    width: 310px;
  }
}

@media (max-width: 430px) {
  .quick-order-hidden-in-small-devices {
    display: none !important;
  }

  .icons-for-small-and-medium-devices {
    margin-right: 0px;
  }

  .header-profile-dropdown-form-small-devices {
    left: auto !important;
    right: auto !important;
    max-width: none !important;
    width: 100%;
    padding: 0px 10px;
  }

  .main-menu nav ul li>a {
    font-size: 12px !important;
    line-height: 40px !important;
  }

  .navbar {
    height: 120px;
  }

  .navFooter {
    margin-right: 30px !important;
    position: relative;
  }

  .navFooter .header-right-wrap div {
    font-size: 14px !important;
  }

  .sidebaricon {
    display: flex;
  }

  .headerLogo {
    margin-left: 25px !important;
    position: relative;
    top: 0px;
    height: fit-content;
  }

  .header-margin {
    margin-top: 30px !important;
    margin-bottom: 2px;
    /* flex-direction: column-reverse; */
    width: 100%;
  }

  .view-cart-count {
    right: 96px !important;
  }

  .profile-header-logo,
  .cart-icon-logo {
    margin-left: 7px !important;
    height: 30px;
    margin-left: 10px;
  }

  .main-logo {
    width: 120px;
    height: 40px;
    margin-top: 0px;
  }

  .home-search-products-bar {
    width: 280px;
  }

  div.header-right-wrap span.ant-input-group-wrapper {
    width: 300px !important;
    height: 30px !important;
    position: relative;
    right: 130px;
    margin-top: 10px;
  }

  .login-signup-button {
    /* margin-left: 60px;
    width: 116px; */
    height: 34px;
    padding: 6px;
    font-size: 10px;
  }

  .nav-search input.ant-input {
    height: 37px !important;
  }

  .search-position {
    position: unset;
  }

  .searchbar-menu {
    width: 80% !important;
    right: 3%;
    margin: auto;
  }

  .header-searchbar {
    width: 235px;
  }

  .navbar {
    padding: 33px 8px 24px 5px;
  }
}
