/*------- 14. Brand logo style  -------*/

.brand-logo-active,
.brand-logo-active-2 {
  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: auto;
      }
    }
  }
  .single-brand-logo,
  .single-brand-logo2 {
    text-align: center;
    img {
      transition: all 0.5s ease 0s;
    }
  }
}

.brand-logo-wrap {
  padding: 100px 90px;
  @media #{$xs-layout} {
    padding: 50px 20px;
  }
}
.swiper-wrapper{
  width: 234px;
}

.brand-logo-area {
  padding-top: 14px;
  background: var(--style-brands-background, #ffffff);
}
