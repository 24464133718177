/*------ 22. contact Page  ------*/

.contact-map {
  position: relative;

  height: 560px;

  @media #{$xs-layout} {
    height: 400px;
  }
}

.contact-info-wrap {
  padding: 120px 70px 116px 90px;

  background-color: #f3f3f3;

  @media #{$lg-layout} {
    padding: 120px 20px 116px 40px;
  }

  @media #{$md-layout} {
    padding: 120px 20px 116px 30px;
  }

  @media #{$xs-layout} {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 50px 20px 45px 30px;
  }

  .single-contact-info {
    display: flex;
    align-items: center;

    margin-bottom: 40px;

    .contact-icon {
      margin-right: 20px;

      i {
        font-size: 20px;
        line-height: 40px;

        display: inline-block;

        width: 40px;
        height: 40px;

        transition: all 0.3s ease 0s;
        text-align: center;

        color: #252525;
        border: 1px solid #252525;
        border-radius: 100%;
      }
    }

    .contact-info-dec {
      p {
        line-height: 1;

        margin: 0 0 9px;

        color: #404040;

        a {
          color: #404040;

          &:hover {
            color: $theme-color;
          }
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &:hover .contact-icon i {
      color: #fff;
      background-color: #252525;
    }
  }
}

.contact-social {
  margin-top: 58px;

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;

    margin: 0 0 17px;

    color: #4d4d4d;
  }

  ul {
    li {
      display: inline-block;

      margin: 0 10px;

      a {
        font-size: 16px;

        color: #4d4d4d;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}

.contact-form {
  padding: 50px 110px 50px 110px;

  background-color: #f3f3f3;

  @media #{$lg-layout} {
    padding: 50px 50px 50px 50px;
  }

  @media #{$md-layout} {
    padding: 50px 30px 50px 30px;
  }

  @media #{$xs-layout} {
    padding: 50px 30px 50px 30px;
  }

  .contact-title {
    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 1;

      margin-bottom: 36px;

      color: #464646;
    }
  }

  .contact-form-style {

    input,
    textarea {
      height: 40px;
      margin-bottom: 17px;
      padding: 2px 14px;

      color: #000;
      border: 1px solid #c1c1c1;
      background: transparent;
    }

    textarea {
      height: 175px;
      margin-bottom: 0;
      padding: 20px 14px;
    }

    button {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;

      margin-top: 38px;
      padding: 15px 52px;

      text-transform: uppercase;

      color: #fff;
      border: none;
      background-color: #404040;

      &:hover {
        background-color: $theme-color;
      }
    }
  }

  p {
    color: #333;

    &.success {
      margin-top: 10px;
    }
  }
}

.contact-form-style {
  .row {
    & div[class^="col-"] {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.contact-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}


.contact-phone-number .form-control {
  border: none;
  background: #f7f7f7;
  border-radius: 64px;
  height: 52px !important;
  width: 100% !important;
  padding-left: 64px !important;
}

.contact-phone-number .flag-dropdown {
  left: 12px !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

.contact-phone-number .selected-flag {
  margin-top: 0px !important;
  margin-left: 10px !important;
}

.contact-form-header {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 292px;
  background-color: var(--style-contact-us-background-color, #ffffff);
}

.contact-form-header div {
  width: 607px;
  height: 107px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.contact-form-header div .label {
  color: var(--style-contact-us-heading-color, #1A1A1A);
  font-family: var(--style-contact-us-heading-family, "Montserrat", sans-serif);
  font-size: calc(var(--style-contact-us-heading-size, 60) * 1px);
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -1.2px;
}

.contact-form-header div .description {
  color: var(--style-contact-us-heading-color, #1A1A1A);
  font-family: var(--style-contact-us-heading-family, '"Poppins", sans-serif');
  font-size: calc(var(--style-contact-us-heading-size, 60) * 0.375px);
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.contact-form-body {
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 60px;
  background-color: var(--style-contact-us-background-color, #ffffff);
}

.contact-form-body .contact-body-first-child {
  display: flex;
  // width: 1560px;
  width: 100vw;
  height: 742px;
  border-radius: 24px;
  justify-content: center;
  box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
  background-color: var(--style-contact-us-form-background-color, #ffffff);
}

.contact-form-body .contact-body-first-child .contact-body-sidebar {
  margin: 16px 22px 24px 22px;
  border-radius: 24px;
  background: var(--style-contact-us-information-background, rgb(234, 30, 81));
  box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
  width: 537px;
  height: 702px
}

.contact-form-body .contact-body-first-child .contact-body-sidebar * {
  color: var(--style-contact-us-information-text-color, #ffffff) !important;
  font-family: var(--style-contact-us-information-text-family, "Montserrat", sans-serif) !important;
}

.contact-form-body .contact-body-first-child .contact-body-sidebar p:nth-child(1) {
  margin: 54px 124px 53px 54px;
  color: #FFF;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
}

.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin: 0px 124px 0px 54px;
}
.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child > div {
  display: flex;
}

.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .first-grand-child,
.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .second-grand-child,
.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .third-grand-child {
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 30px
}

.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .first-grand-child img,
.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .second-grand-child img,
.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .third-grand-child img {
  margin-right: 10px;
}

.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .first-grand-child span,
.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .second-grand-child span,
.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .third-grand-child span {
  color: white
}

.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-second-child {
  margin: 54px 124px 53px 54px;
  display: flex;
}

.contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-second-child img {
  margin-right: 24px;
  cursor: pointer;
}

.contact-form-body .contact-body-first-child .contact-right-panel {
  margin: 60px 60px 60px 30px;
  display: flex;
  flex-wrap: wrap;
  width: 1001px;
  height: fit-content;
  gap: 24px;
}

.contact-form-body .contact-body-first-child .contact-right-panel label:not(.text-danger),
.contact-form-body .contact-body-first-child .contact-right-panel label *:not(.text-danger){
  color: var(--style-contact-us-form-text-color, #000) !important;
  font-family: var(--style-contact-us-form-text-family, "Montserrat", sans-serif) !important;
}

.contact-form-body .contact-body-first-child .contact-right-panel .input-group-container {
  display: flex;
  width: 100%;
  gap: 16px;
}

.contact-form-body .contact-body-first-child .contact-right-panel .input-group-container div {
  width: 100%;
}

.contact-form-body .contact-body-first-child .contact-right-panel .contact-action {
  width: 100%;
  display: flex;
  justify-content: flex-end
}

.contact-form-body .contact-body-first-child .contact-right-panel .contact-action button {
  display: flex;
  width: 294px;
  height: 52px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 32px;
  background: var(--style-contact-us-information-background, rgb(234, 30, 81));
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: #FFF;
  border: none
}
.contact-us-email-phone {
  display: flex;
  width: 100%;
  gap: 16px
}


@media only screen and (max-width: 430px) {
  .contact-form-header {
    width: 100%;
  }

  .contact-form-header div {
    margin-bottom: 0px;
    margin-top: 30px;
    grid-gap: 0px
  }

  .contact-form-header div .label {
    color: var(--Gray-900, #1A1A1A);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .contact-form-header div .description {
    width: 248px;
    color: var(--Gray-500, #808080);
    text-align: center;
    /* Display Font/14px/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
  }

  .contact-form-body {
    width: 100%
  }

  .contact-form-body .contact-body-first-child .contact-body-sidebar {
    width: 100%;
    margin: 0;
    height: 474px;
  }

  .contact-form-body .contact-body-first-child .contact-body-sidebar p:nth-child(1) {
    color: var(--White, #FFF);
    /* Display Font/16px/Medium */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
  }

  .contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .first-grand-child span,
  .contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .second-grand-child span,
  .contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .third-grand-child span {
    color: var(--White, #FFF);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    /* 250% */
  }

  .contact-form-body,
  .contact-form-body .contact-body-first-child {
    flex-direction: column;
  }

  .contact-form-body .contact-body-first-child {
    width: 100vw;
    height: max-content;
    padding: 15px
  }

  .contact-form-body .contact-body-first-child .contact-right-panel .input-group-container {
    flex-wrap: wrap;
  }

  .contact-form-body .contact-body-first-child .contact-right-panel {
    width: 100%;
    margin: 18px 0 0 0;
  }

  .contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child {
    grid-gap: 0px;
    gap: 32px;
    margin-top: 0px;
    margin-left: 50px;
    margin-right: 0px !important
  }

  .contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-second-child {
    margin-left: 53px;
    margin-top: 77px;
    margin-bottom: 0px;
    margin-right: 0px;
  }

  .contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .second-grand-child {
    width: max-content;
  }

  .contact-form-body .contact-body-first-child .contact-body-sidebar .contact-body-sidebar-first-child .third-grand-child {
    width: 280px;
  }

  .contact-form-body .contact-body-first-child .contact-right-panel .contact-action button {
    width: 135px;
    font-size: 14px;
    height: 40px;
  }
  .contact-us-email-phone {
    flex-direction: column;
  }
}
