.quick-order-main-area {
  margin-left: 70px;
  margin-right: 70px;
}

.quick-order-search-filter-class {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.quick-order-search-filter-class label {
  color: #1a1a1a;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.search-product .ant-input-group-addon:first-child {
  border-radius: 22px 0px 0px 22px;
  border: 1px solid #f2f2f2;
}

.search-product input.ant-input {
  height: 48px !important;
  width: 662px;
  background: #f6f6f6;
  border: none;
  border-radius: 27px;
}

.search-product input:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.search-product input::placeholder {
  color: #808080;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.quick-order-scan-qty-class {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-left: 24px;
}

.quick-order-scan-qty-class label {
  color: #1a1a1a;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */
}

.quick-order-scan-qty-class input {
  width: 224px;
  height: 51px !important;
  border-radius: 27px;
  background: #f6f6f6;
  padding-left: 20px;
}

.product-section {
  width: 70%;
}

.shopping-row {
  display: flex;
  gap: 24px;
}

.shopping-quick-order-main-area {
  margin-left: 70px;
  margin-right: 70px;
}

.shopping-quick-order-main-area {
  margin-left: 70px;
  margin-right: 70px;
}

.shopping-cart-heading {
  font-family: "Montserrat";
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -0.02em;
}

.shopping-heading-row {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.qo-unit {
  /* width: 80px; */
  margin-top: 15px;
  padding-top: 0;
  display: flex;
  justify-content: center;
  vertical-align: top;
}
.cart-unit {
  margin-top:17px;
  padding-top:0;
  display: flex;
  justify-content: center;
  vertical-align:top;
}

.pro-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 50px;
  overflow: hidden;
  /* width: 100% !important; */
  color: #1A1A1A;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.promotion-quick {
  display: inline;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  /* width: 100%; */
  margin: 0;
  margin-left: 20px;
  z-index: 1;
}
.promotion-text-quick {
  width: 65px;
  min-width: 65px;
  max-height: 50px;
  padding: 0px 11px;
}
.promotion-text-quick-four {
  width: 45px;
  max-height: 50px;
  padding: 0px 11px;
}
.promotion-col {
  display: flex;
  flex-direction: column;
}

.cart-labels {
  color: var(--Gray-500, #808080);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.pro-name-cart {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 50px;
  overflow: hidden;
  width: 100% !important;
  color: #1A1A1A;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.customized-input .anticon-delete svg,
.customized-input .anticon-delete svg path {
  fill: var(--style-primary-color);
}
.del-item svg path{
  stroke: var(--style-primary-color);
}

.product-table {
  max-height: 1012px;
  max-width: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #7a7a7a #f7f7f7;
  /* For Firefox: thumb and track color */
}

.quick-cart {
  width: 32%;
  height: 1012px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
}

.cart-added-product {
  width: 91%;
  height: 670px;
  overflow-y: auto;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #7a7a7a #f7f7f7;
  /* For Firefox: thumb and track color */
  margin-top: 24px;
  margin-left: 20px;
  margin-bottom: 24px;
}

.cart-table-label {
  color: #808080;
  font-family: Montserrat;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px;
}

/* For Webkit browsers like Chrome and Safari */
.cart-added-product::-webkit-scrollbar,
.product-table::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

.cart-added-product::-webkit-scrollbar-track,
.product-table::-webkit-scrollbar-track {
  background: #f7f7f7;
  /* Background of the scrollbar track */
  border: 1px solid #b3b3b3;
  /* background-color: #F2F2F2; */
  border-radius: 50px;
}

.cart-added-product::-webkit-scrollbar-thumb,
.product-table::-webkit-scrollbar-thumb {
  border-radius: 100px;
  height: 150px;
  background: #7a7a7a;
  /* border:1px solid #B3B3B3;Creates padding around the scrollbar thumb */
}

.product-section .image-column {
  text-align: start;
  width: 70px;
  /* height: 59px; */
}
.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-self: center;
  height: 50%;
}


.product-section .product-name-column {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px; */
  text-align: start;
  width: 200px;
}

.product-section .upc {
  width: 109px;
  text-align: center;
}
.product-section .timer {
  width: 150px;
  text-align: center;
}

.product-section .unit {
  width: 76px;
}

.product-section .qty {
  width: 127px;
}

.product-section .price {
  width: 92px;
  text-align: center;
}
@media (max-width: 1920px) {
  .item {
    width: 2%;
  }
  .product-section .product-table {
    width: 100% !important;
  }

  .quick-cart .cart-added-product table {
    width: 100%;
  }
}

@media (max-width: 2560px) {
  .item {
    width: 2%;
  }
  .product-section .product-table {
    width: 100% !important;
  }

  .quick-cart .cart-added-product table {
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .product-section {
    max-width: 60% !important;
    margin-right: 40px;
  }

  .quick-cart {
    width: 50% !important;
  }
}
@media (max-width: 435px) {
  .product-section {
    width: 100%;
  }

  .shopping-row {
    display: unset;
  }

  .shopping-quick-order-main-area {
    /* margin: 0px 16px !important; */
    margin-left: 16px;
    margin-right: 16px;
  }

  .shopping-cart-heading {
    font-family: "Montserrat" !important;
    font-size: 28px !important;
    font-weight: 500 !important;
    line-height: 72px !important;
    letter-spacing: -0.02em !important;
  }

  .shopping-heading-row {
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .product-table {
    width: 100% !important;
  }

  .cart-added-product {
    width: 100% !important;
    /* flex-direction: column; */
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin: 0px !important;
    padding: 16px;
  }

  .checkout-button {
    width: 100% !important;
    margin: 0px 2px 8px !important;
  }

  .checkout-btn-container {
    margin: 0px auto !important;
    max-width: 85vw;
    padding: 4px;
  }

  .quick-cart {
    width: 98% !important;
    height: auto !important;
  }

  .cart-lineItems {
    display: block !important;
    width: 48%;
    margin: 0px !important;
  }

  .cart-lineItems-pretax {
    display: flex !important;
    width: 360px;
    margin: 0px !important;
  }

  .product-section {
    max-height: 320px;
    overflow: auto;
  }

  .pro-table-haed thead {
    display: none;
  }

  .pro-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 50px;
    width: 100% !important;
    color: #1A1A1A;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }

  .pro-name-td {
    width: calc(100vw - 208px);
  }

  .pro-unit {
    width: 100px !important;
  }

  .cart-values {
    color: var(--Gray-900, #1A1A1A);
    text-align: right;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .cart-labels {
    color: var(--Gray-500, #808080);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .add-style {
    padding-left: 24px;
  }

  .out-of-stock {
    display: none !important;
  }

  .del-item {
    padding-top: 8px !important;
  }

  .grid-container {
    max-width: 100vw;
    flex-wrap: wrap;
    display: flex;
    border-radius: 8px;
    box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
    padding: 8px;
    margin: 8px;
    border-bottom: none !important;
  }

  .table-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .item {
    width: auto;
    display: table-cell !important;
    padding: 0 !important;
  }

  .product-section .price {
    width: calc(100vw - 225px) !important;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    /* width: 170px !important; */
  }

  .cart-head {
    padding: 20px;
    margin: 0px !important;
  }

  .product-section .image-column {
    max-width: 40px !important;
  }

  .ant-image-img {
    width: 100% !important;
    height: 50px !important;
    object-fit: contain !important;
  }

  .ant-image {
    width: 100% !important;
    height: 50px !important;
  }

  .empty-cart {
    height: 90px !important;
    max-width: 127px !important;
  }

  .goto-shopBtn {
    margin-bottom: 12px !important;
  }

  .first-half .discription {
    width: 100% !important;
  }
  .product-section {
    max-width: 100% !important;
    margin-right: 40px;
  }

  .quick-cart {
    width: 100% !important;
  }
  .promotion-text-quick {
    width: 55px;
    min-width: 55px;
  }
  .promotion-text-quick-four {
    width: 35px;
    max-height: 35px;
    min-width: 37px;
  }
}