.age-restriction-modal {
  p {
    color: #1a1a1a;
    text-align: center;
    font-weight: 400;
  }
  .main-modal {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  .modal-heading {
    color: #1a1a1a;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }

  .age-verify-button {
    display: flex;
    height: 30px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: 5px;
    border-radius: 8px;
    background: #f2f2f2;
  }
  .age-verify-color {
    background: var(--style-primary-color);
    color:white;
  }
  .ant-modal-content {
    border-radius: 6px;
    padding: 40px 24px;
  }
  .main-logo {
    max-width: 192px;
    max-height: 45px;
    margin-bottom: 5px;
  }
}
