.header-tmpl{
  @apply
    tw-w-full
    tw-bg-white
    tw-z-20
    tw-top-0
    tw-shadow-3xl;
  
  background-color: var(--style-nav-background, #ffffff);
  nav{
    
    *{
      font-family: var(--style-primary-font-family, "Montserrat", sans-serif)!important
    }
    @apply
      tw-relative
      tw-mx-auto
      tw-flex
      tw-items-center
      tw-justify-between
      tw-px-4
      tw-py-2
      tw-gap-x-4
      tw-flex-wrap
      lg:tw-flex-nowrap;

    .header-branding{
      @apply
        tw-shrink-0
        tw-flex;

      > a{
        
        img{
          @apply 
            tw-h-10
            tw-w-auto;
        }
      }
    }
  }

  .header-navigation{
    @apply
      tw-hidden
      lg:tw-flex
      lg:tw-gap-x-10;

    .navigation-item{
      @apply 
        tw-flex
        tw-items-center
        tw-gap-x-1
        tw-font-medium
        tw-text-gray-900;

      color: var(--style-nav-text-color, #555252);
      font-family: var(--style-primary-font-family, "Montserrat", sans-serif);
      font-size: calc(var(--style-nav-font-size, 16) * 1.1px);

      &:hover{
        color: var(--style-nav-text-color-hover) !important;
      }
      
      &.active {
        color: var(--style-nav-text-color-hover);
      }
    }

    .navigation-dropdown{

      .dropdown-list{
        @apply
          tw-z-50
          tw-absolute
          tw-flex-col
          tw-p-3
          tw-flex-nowrap
          tw-hidden
          tw-rounded
          tw-shadow-xl;
        
        background: var(--style-nav-background, #ffffff);

        a {
          @apply
            tw-py-2
            tw-px-2
            tw-whitespace-nowrap;
          color: var(--style-nav-text-color, #555252);
        }
      }

      &:hover{
        .dropdown-list{
          @apply
            tw-flex;

          a:hover,
          .active {
            color: var(--style-nav-text-color-hover) !important;
          }
        }
      }
    }
  }

  & > .header-actions{
    @apply
      max-lg:tw-mt-2;
  }

  .header-actions{
    @apply
      tw-flex
      tw-basis-full
      tw-text-sm
      tw-shrink-0
      tw-justify-center
      lg:tw-flex
      lg:tw-mt-0
      lg:tw-flex-row
      lg:tw-items-center
      lg:tw-justify-end
      lg:tw-basis-auto
      lg:tw-gap-y-0
      lg:tw-flex-nowrap;

    .action-block{
      @apply
        tw-flex
        tw-px-2;

      color: var(--style-nav-text-color, #000000);

      .action-item{
        @apply
          tw-flex
          tw-items-center
          tw-gap-x-1
          tw-cursor-pointer
          tw-whitespace-nowrap;

        color: var(--style-nav-text-color, #000000);
        &:hover{
          color: var(--style-nav-text-color-hover) !important
        }
        .action-item-icon{
          svg path{
            fill: var(--style-nav-text-color, #000000);
          }

          &:hover{
            svg path{
              fill: var(--style-nav-text-color-hover) !important
            }
          }
        }

        span{
          @apply tw-whitespace-nowrap;
        }
      }

      .action-search{
        @apply
          tw-relative
          tw-flex
          tw-border
          tw-border-solid
          tw-border-gray-100
          tw-bg-gray-100
          tw-rounded-xl;

        input{
          @apply
            tw-block
            tw-w-full
            tw-text-gray-900 
            placeholder:tw-text-gray-400
            placeholder:tw-text-sm
            focus:tw-ring-2 
            focus:tw-ring-inset 
            focus:tw-ring-indigo-600 
            tw-px-4
            tw-py-2
            tw-bg-transparent
            tw-border-0
            tw-rounded-xl
            tw-pr-10
            md:tw-w-32
            lg:tw-w-64;
        }

        button{
          @apply
            tw-absolute
            tw-w-8
            tw-h-full
            tw-bg-white
            tw-bg-opacity-5
            tw-rounded-xl;

          svg{
            @apply
              tw-mx-auto
            tw-fill-white;
          }

          &.accent-button{
            background-color: var(--style-primary-color);

            path{
              fill: white;   
            }
          }
        }

        .search-dropdown {
          background-color: var(--style-nav-background, #ffffff);

          @apply
            tw-w-full
            tw-cursor-pointer
            tw-shadow-2xl
            tw-rounded-sm
            tw-top-full
            tw-absolute
            tw-z-50;

          .search-dropdown-item {
            @apply
              hover:tw-bg-black
              hover:tw-bg-opacity-5
              tw-px-3
              tw-py-2
              tw-gap-5
              tw-flex
              tw-items-center
              tw-border-b
              tw-border-solid
              tw-border-gray-200;

            img{
              @apply
                tw-aspect-square
                tw-object-cover
                tw-w-8
                tw-h-8; 
            }

            p {
              @apply
                tw-line-clamp-2
                tw-text-ellipsis
                tw-overflow-hidden
                tw-text-sm
                tw-flex
                tw-flex-grow
                tw-items-center
                tw-gap-5
                tw-justify-between;
              color: var(--style-nav-text-color);

              color: var(--style-nav-text-color, #000000);
              span {
                color: var(--style-primary-color);

                @apply
                  tw-text-base
                  tw-ml-auto;
              }
            }
          }
        }
      }

      & + .action-block{
        @apply
          tw-border-l
          tw-border-solid
          tw-border-gray-100;
      }
    }

    .icon-search{
      @apply
        tw-relative;
      
      .action-block {
        @apply
          tw-w-full;
      }
    }

    &.search-overlay {
      @apply
        tw-absolute
        tw-top-3
        tw-bottom-3
        tw-left-1/4
        tw-right-1/4
        max-md:tw-left-10
        max-md:tw-right-10
        tw-rounded-lg
        tw-py-1
        tw-px-1
        lg:tw-p-3
        tw-shadow-md;

      background-color: var(--style-nav-background, #ffffff);

      input {
        @media not all and (min-width: 1024px) {
          height: 100% !important;
          padding-top: 0.25rem !important;
          padding-bottom: 0.25rem !important;
        }
      }
    }
  }

  .header-categories{
    *{
      font-family: var(--style-secondary-font-family, "Montserrat", sans-serif)!important
    }
    @apply
      tw-flex
      tw-text-nowrap
      tw-items-center
      tw-justify-center
      tw-px-4
      lg:tw-px-10
      tw-border-t
      tw-border-solid
      tw-border-t-gray-200;

    .mega-menu {
      overflow: auto;
      background-color: var(--style-nav-categories-background, #ffffff);

      @apply
        tw-fixed
        tw-top-full
        tw-left-0
        tw-right-0
        tw-mt-0
        tw-border-gray-200 
        tw-shadow-sm 
        tw-border
        tw-rounded
        tw-z-10;
    
      font-size: calc(var(--style-nav-categories-dropdown-font-size, 14) * 1px);

      .dropdown-content {
        @apply 
          tw-grid 
          tw-max-w-screen-xl 
          tw-px-4 
          tw-py-5 
          tw-mx-auto 
          tw-grid-cols-3
          md:tw-px-6;

        b{
          color: var(--style-nav-categories-color, #374151);
          font-family: var(--style-nav-categories-font-family, "Montserrat", sans-serif)!important;
          font-size: calc(var(--style-nav-categories-font-size, 14) * 1px);
        }
        > ul {
          @apply 
            tw-space-y-4 
            sm:tw-mb-4 
            md:tw-mb-0;
    
          li a {
            @apply 
              hover:tw-underline 
              hover:tw-text-blue-600;
          }
        }
      }
    
      .brand-info {
        p,
        .brand-title {
          @apply 
            tw-mb-2 
            tw-font-semibold;
          
          color: var(--style-primary-color) !important;
          font-family: var(--style-nav-categories-font-family, "Montserrat", sans-serif)!important;
          font-size: calc(var(--style-nav-categories-font-size, 14) * 2px);
          &.boarded{

            color: var(--style-nav-categories-background, #ffffff);
            text-shadow:
              -2px -2px 0 var(--style-primary-color),
              2px -2px 0 var(--style-primary-color),
              -2px 2px 0 var(--style-primary-color),
              2px 2px 0 var(--style-primary-color);
          }
        }
    
        p {
          @apply 
            tw-whitespace-pre-wrap
            tw-mb-2 
            tw-text-gray-500;
        }
    
        a {
          @apply 
            tw-inline-flex 
            tw-items-center 
            tw-text-sm 
            tw-font-medium
            tw-text-blue-600 
            tw-overflow-hidden
            tw-aspect-square
            hover:tw-underline;
        }
      }

      .category-list-container {
        @apply tw-flex tw-flex-row;
      }
      
      .category-list {
        @apply 
          tw-list-none 
          tw-p-0 
          tw-min-w-32
          tw-ml-0
          tw-pl-4;
        
        > li {
          @apply 
            tw-py-1
            tw-px-2
            tw-capitalize
            tw-text-gray-800 
            tw-cursor-pointer 
            tw-rounded;

          a {
            color: var(--style-nav-categories-color, #374151);
            font-family: var(--style-nav-categories-font-family, "Montserrat", sans-serif)!important;
            font-size: calc(var(--style-nav-categories-font-size, 14) * 1px);

            @apply
              tw-leading-normal
              tw-block
              tw-overflow-hidden
              tw-text-ellipsis
              tw-no-underline;
          }

          &.active-category{
            @apply tw-bg-transparent
          }
          &:hover a {
            color: var(--style-nav-text-color-hover) !important;
          }
          &.active-category a{
            color: var(--style-primary-color, #555252);
          }
        }
      }

      .mega-menu-brands-list {
        @apply 
          tw-grid 
          tw-grid-cols-1 
          sm:tw-grid-cols-4
          tw-gap-4 
          tw-my-4;

        &.brands-list-text{
          @apply
            tw-flex-col
            tw-flex;

          a {
            color: var(--style-nav-categories-color, #374151);

            &:hover,
            &.active {
              color: var(--style-primary-color) !important;
            }
          }
        }
        .brand-alt{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: var(--style-nav-categories-font-family, "Montserrat", sans-serif)!important;

          color: var(--style-nav-categories-color);

          &:hover{
            color: var(--style-nav-text-color-hover) !important;
          }
        }
      }
      
      .mega-menu-brand-item {
        @apply 
          tw-cursor-pointer
          tw-rounded-lg;
      }
      
      .mega-menu-brand-link {
        @apply 
          tw-flex
          tw-items-center
          tw-justify-center
          tw-aspect-square
          tw-text-gray-700 
          tw-no-underline 
          tw-w-full;
      }
      
      .mega-menu-brand-image {
        @apply
          tw-rounded;
      }
    }
  }

  .category-item{
    @apply
      tw-px-5
      tw-py-3
      tw-relative
      tw-whitespace-nowrap
      tw-font-medium
    tw-text-gray-900
      tw-text-base;
    
    font-size: calc(var(--style-nav-categories-font-size, 16) * 1px);
    color: var(--style-nav-text-color, #555252) !important;
    svg{
      fill: var(--style-nav-text-color);
    }

    &:hover{
      color: var(--style-nav-text-color-hover) !important;

      svg{
        fill: var(--style-nav-text-color-hover);
      }
    }
    button{
      color: var(--style-nav-text-color, #555252);
      &:hover{
        color: var(--style-nav-text-color-hover) !important
      }
    }

    &.has-sub-category:hover{
      &:after {
        content: "";
        height: 200%;
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
      }
    }
  }

  .header-dropdown {
    position: relative;
    
    &:hover{
      .header-list{
        @apply
          tw-block;
      }
    }

    .header-dropdown-button {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: calc(var(--style-nav-categories-font-size, 16)* 1.1px);

    }

    .header-list {
      background-color: var(--style-nav-background, #ffffff);

      @apply
        tw-hidden
        tw-absolute
        tw-top-full
        tw-right-0
        tw-border
        tw-border-solid
        tw-border-gray-300
        tw-rounded
        tw-px-3
        tw-py-2
        tw-z-10
        tw-max-h-144
        tw-overflow-x-hidden
        tw-overflow-y-auto;
      
      * {
        color: var(--style-nav-text-color, #000000);

        &:hover {
          color: var(--style-nav-text-color-hover)
        }
      }

      .active {
        color: var(--style-nav-text-color-hover)
      }

      @media (max-width: 768px) {
        background: transparent;
        border: 0;
        padding: 0;
        font-size: calc(var(--style-nav-font-size, 16) * 1.1px);
        padding-left: 0;
        position: static;
      }
    }
  }

  .expand-child{
    color: var(--style-nav-categories-color, #374151);
  }

  .rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out; /* Smooth transition */
  }

  .color-primary{
    color: var(--style-primary-color);
    text-decoration: none;
  }

  .categories-accordion{
    a {
      color: var(--style-nav-categories-color, #374151);
      font-family: var(--style-nav-categories-font-family, "Montserrat", sans-serif)!important;
      font-size: calc(var(--style-nav-categories-font-size, 14) * 1px);

      &:hover{
        color: var(--style-nav-text-color-hover);
      }
      &.active-category{
        color: var(--style-primary-color, #555252);
      }
    }
  }

  .mobile-navigation{
    @apply
      tw-flex
      tw-flex-col
      tw-z-50
      tw-fixed
      tw-top-0
      tw-left-0
      tw-h-full
      tw-w-1/3;
    background-color: var(--style-nav-background, #ffffff);

    .tabs{
      @apply tw-my-4;

      button {
        color: var(--style-nav-text-color, #000000);
        @apply
          tw-text-base
          tw-py-3
          tw-px-4;

        &.active{
          color: var(--style-nav-text-color-hover);
          border-color: var(--style-nav-text-color-hover, #555252);
          @apply
            tw-border-b-2
            tw-border-solid;
        }
      }

      svg{
        fill: var(--style-nav-text-color, #000000);

        &:hover{
          fill: var(--style-nav-text-color-hover) !important
        }
      }
    }

    .tab-content{
      @apply
        tw-border-t
        tw-border-solid
        tw-border-gray-100
        tw-h-full
        tw-pb-6
        tw-overflow-y-auto;

      > .action-block{
        > a,
        .action-item{
          color: var(--style-nav-text-color, #555252);

          @apply
            tw-transition-colors
            tw-w-full
            tw-border-b
            tw-border-solid
            tw-border-gray-100
            tw-py-3
            tw-px-4;


          :hover{
            color: var(--style-nav-text-color-hover) !important;
          }
        }
      }
    }

    .header-navigation {
      @apply
        tw-px-0
        tw-flex-col
        tw-items-start;

      .navigation-item {
        @apply
          tw-w-full
          tw-border-b
          tw-border-solid
          tw-border-gray-100
          tw-py-3
          tw-px-4;
      }
    }
    .header-categories{
      @apply
        tw-border-t-0
        tw-px-0
        tw-flex-col
        tw-items-start;

      > .category-container {
        @apply
          tw-w-full
          tw-border-b
          tw-border-solid
          tw-border-gray-100
          tw-pr-4;

        > a {
          @apply tw-font-semibold;
        }

        > .nested-children{
          @apply
            -tw-mx-4;

          .nested-children{
            @apply
              tw-pr-0;
          }
        }
        .nested-children{
          @apply
            tw-pr-4
            tw-pl-6;
          background-color: var(--style-nav-categories-background, #374151);

          a {
            color: var(--style-nav-categories-color, #374151)!important;
          }

          &:hover a,
          &.active-category a{
            color: var(--style-primary-color, #555252);
          }
        }
        .category-item:hover{
          color: var(--style-nav-text-color-hover)!important
        }
      }
    }

    .action-item-icon.header-dropdown-button {
      @apply
        tw-mb-3;
    }

    .header-list{
      position: relative;
      border: 0;
      background: transparent;
      padding: 0;
      display: block;

      .header-icon-group-menu{
        font-size: calc(var(--style-nav-font-size, 16)* 1.1px);

        &:last-child{
          @apply tw-border-b-0;
        }

        @apply
          tw-w-full
          tw-border-b
          tw-border-solid
          tw-border-gray-100
          tw-pl-0
          tw-pr-3
          tw-px-4;
      }

    }
    .tab-content{
      .action-item.header-dropdown{
        @apply tw-px-3;
      }
    }
    .profile-icon{
      @apply tw-hidden;
    }

    @media not all and (min-width: 1024px) {
      @apply tw-w-1/2;
    }

    @media not all and (min-width: 768px) {
      @apply tw-w-full;
    }
  }

  .mobile-search{
    @apply
      tw-py-3
      tw-px-4
      tw-border-t
      tw-border-solid
      tw-border-gray-100
      lg:tw-hidden;
  }
}


.mobile-menu-bar{
  stroke: var(--style-nav-text-color, #000000);

  &:hover{
    stroke: var(--style-nav-text-color-hover) !important
  }
}
.brand-alt{
  font-family: var(--style-nav-categories-font-family, "Montserrat", sans-serif)!important;
  color: var(--style-nav-text-color);
}
