/* Montserrat.css */

@font-face {
    font-family: 'Montserrat';
    src: url('./../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
  
@font-face {
    font-family: 'Montserrat';
    src: url('./../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }