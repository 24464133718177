/*------- 11. About style ------ */

.welcome-content {
  h5 {
    font-weight: 500;
    font-size: 16px;
    color: #666;
    margin: 0;
    letter-spacing: 0.3px;
  }

  h1 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin: 8px 0 30px;
    color: #433f3f;
    position: relative;
    display: inline-block;
    padding: 0 0 23px;
    line-height: 1;

    &:before {
      position: absolute;
      background-color: #070508;
      height: 3px;
      width: 70px;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
    }
  }

  p {
    font-size: 16px;
    color: #4b4b4b;
    line-height: 30px;
    width: 66%;
    margin: 0 auto;

    @media #{$lg-layout} {
      width: 80%;
    }

    @media #{$md-layout} {
      width: 95%;
    }

    @media #{$xs-layout} {
      width: 100%;
      font-size: 15px;
    }
  }
}

/* about page */

.single-count {
  .count-icon {
    i {
      font-size: 50px;
      line-height: 1;
      display: inline-block;

      @media #{$md-layout} {
        font-size: 45px;
      }

      @media #{$xs-layout} {
        font-size: 45px;
      }
    }
  }

  h2 {
    &>span {
      color: $theme-color;
      font-size: 40px;
      font-weight: bold;
      margin: 25px 0 12px;
      display: inline-block;

      @media #{$md-layout} {
        margin: 10px 0 8px;
        font-size: 35px;
      }

      @media #{$xs-layout} {
        margin: 6px 0 8px;
        font-size: 35px;
      }
    }
  }

  span {
    color: #333;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
  }
}

.team-wrapper {
  background-color: #f7f7f7;

  .team-img {
    position: relative;

    img {
      width: 100%;
    }

    .team-action {
      background: #fff none repeat scroll 0 0;
      left: 0;
      opacity: 0;
      padding: 10px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 70%;
      transform: translateY(-50%);
      transition: all 0.6s ease 0s;
      width: 100%;
      z-index: 5;

      a {
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        font-size: 14px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        width: 30px;
        margin: 0 3px;

        &.facebook {
          border: 1px solid #3b5998;
          background-color: #3b5998;

          &:hover {
            background-color: transparent;
            color: #3b5998;
          }
        }

        &.twitter {
          border: 1px solid #55acee;
          background-color: #55acee;

          &:hover {
            background-color: transparent;
            color: #55acee;
          }
        }

        &.instagram {
          border: 1px solid #c32aa3;
          background-color: #c32aa3;

          &:hover {
            background-color: transparent;
            color: #c32aa3;
          }
        }
      }
    }
  }

  .team-content {
    padding: 20px 10px 23px;

    h4 {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 3px;
      text-transform: capitalize;
    }

    span {
      font-size: 15px;
      font-style: italic;
    }
  }

  &:hover .team-action {
    top: 50%;
    opacity: 1;
  }
}

.single-mission {
  h3 {
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 20px;
    text-transform: capitalize;
    line-height: 22px;
    color: #333;

    @media #{$xs-layout} {
      margin: 0 0 10px;
    }
  }

  p {
    margin: 0;
  }
}

.about-brand-logo {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

// Our team carousel

.wrapper {
  width: 100vw;
  position: relative;
  margin: 26px 19px 21px 64px;
}

.wrapper i {
  top: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;
  font-size: 1.25rem;
  position: absolute;
  text-align: center;
  line-height: 50px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
  transform: translateY(-50%);
  transition: transform 0.1s linear;
}

.wrapper i:active {
  transform: translateY(-50%) scale(0.85);
}

.wrapper i:first-child {
  left: -22px;
  background: #EA1E51;
}

.wrapper i:first-child img {
  margin-bottom: 3px;
  height: 20px;
}

.wrapper i:last-child {
  right: 5px;
  background: #EA1E51;
}

.wrapper i:last-child img {
  margin-bottom: 3px;
  height: 20px;
}

.wrapper .team-carousel {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% / 3) - 12px);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 16px;
  border-radius: 8px;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.team-carousel::-webkit-scrollbar {
  display: none;
}

.team-carousel.no-transition {
  scroll-behavior: auto;
}

.team-carousel.dragging {
  scroll-snap-type: none;
  scroll-behavior: auto;
}

.team-carousel.dragging .team-card {
  cursor: grab;
  user-select: none;
}

.team-carousel .team-card {
  scroll-snap-align: start;
  margin: 12px;
  height: auto;
  list-style: none;
  cursor: pointer;
  padding-bottom: 15px;
  flex-direction: column;


  border-radius: 24px;
  background: #FFF;
  box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
}

.team-carousel .team-card .img {
  padding: 16px 6px;
  height: 295px;
}

.team-card .img img {
  object-fit: cover;
  border: 4px solid #fff;
  max-width: 100%;
  max-height: 100%;
}

.team-carousel .team-card h2 {
  font-weight: 500;
  font-size: 1.56rem;
  margin: 30px 0 5px;
}

.team-carousel .team-card span {
  color: #6A6D78;
  font-size: 1.31rem;
}

.mission-vision-div {
  width: 401px;
  height: 432px;
}

.mission-vision-img {
  max-width: 100%;
  max-height: 100%;
}

.div-with-mainImg {
  height: 600px;
  width: 1070px;
  margin-left: 70px;
}

.div-main-img {
  max-width: 100%;
  max-height: 100%;
}

.about-us-team {
  // height: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.div-with-mainContent {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 685px;
  margin-left: 70px;
  margin-top: 40px;
}

.div-our-team {
  width: 1555px;
  height: 638px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.excluded {
  color: #EA1E51;
  font-family: Montserrat;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -0.96px;
}

.number-selection {
  display: flex;
  justify-content: space-around;
  width: 1500px;
  height: 240px;
}

.number-selection-info {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  // margin-left: 150px;
}

.our-mission-heading {
  display: flex;
  // height: 398px;
  /* width: "753px",*/
  flex-direction: column;
  /* alignItems: "flex-start",*/
  // justify-content: center;
  gap: 24px;
}

.our-mission-text {
  overflow: hidden;
  width: 637px;
  text-overflow: ellipsis;
  /* whiteSpace: "nowrap",*/
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.our-vision-main {
  display: flex;
  // height: 398px;
  /* width: "753px",*/
  flex-direction: column;
  /* alignItems: "flex-start",*/
  // justify-content: center;
  gap: 24px;
  /* marginRight: "90px",*/
  margin-left: 20px;
}

.our-vision-text {
  overflow: hidden;
  width: 637px;

  text-overflow: ellipsis;
  /* whiteSpace: "nowrap",*/
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.team-member-name {
  align-self: stretch;
  color: #1a1a1a;
  font-family: var(--style-primary-font-family, "Montserrat", sans-serif);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-member-designation {
  color: #808080;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-member-description {
  color: #808080;
  font-family: var(--style-secondary-font-family, "Poppins", sans-serif);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statistics-value {
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}

.statistics-caption {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.mainContentDesc {
  display: flex;
  width: 637px;
  /* height: "143px",*/
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.our-team-heading {
  color: #1a1a1a;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -0.96px;
}

.our-team-card {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
}

@media screen and (max-width: 1500px) {
  .excluded {
    font-size: 30px;
    line-height: 60px;
  }
}

@media screen and (max-width: 1367px) {
  .about-us-team {
    width: 1300px;
  }

  .div-with-mainImg {
    height: 400px;
    width: 900px;
    margin-left: 70px;
  }

  .div-with-mainContent {
    width: 600px;
    margin-top: 30px;
  }

  .div-our-team {
    width: 1500px;
    height: 600px;
  }

  .excluded {
    font-size: 28px;
  }
}

@media screen and (max-width: 900px) {
  .wrapper .team-carousel {
    // grid-auto-columns: calc((100% / 2) - 9px);
  }

  .about-us-team {
    width: 700px;
  }

  .team-carousel .team-card .img {
    height: 218px;
  }

  .div-with-mainContent {
    width: 340px;
    margin-top: 20px;
  }

  .excluded {
    font-size: 18px;
    line-height: 30px;
  }

  .our-mission-text {
    width: 400px;
  }

  .our-mission-heading {
    height: 200px;
    width: 50%;
  }

  .mission-vision-div {
    width: 38% !important;
  }

  .our-vision-main {
    width: 50%;
    height: 200px;
  }

  .our-vision-text {
    width: 100%;
  }

  .wrapper .team-carousel {
    gap: 12px;
  }

  .wrapper {
    width: 100%;
  }

  .team-carousel .team-card span {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper .team-carousel {
    grid-auto-columns: 100%;
  }
}

@media only screen and (max-width: 430px) {
  .mission-vision-div {
    width: 100% !important;
  }

  .excluded {
    font-size: 20px;
  }

  .our-team-heading {
    font-size: 25px;
  }

  .number-selection {
    flex-wrap: wrap;
  }

  .statistics-value {
    font-size: 20px;
    line-height: 10px;
  }

  .statistics-caption {
    font-size: 12px;
    line-height: 30px;
  }

  .our-mission-heading {
    width: -webkit-fill-available;
  }

  .div-with-mainImg {
    margin: 0px !important;
    max-width: 100%;
    height: 340px !important;
  }

  .div-with-mainImg img {
    width: 100%;
    padding: 0px 1px;
    margin: 40px 0px;
  }
  .aboutUs-container{
      padding: 10px;
      background-color: var(--style-about-us-background, #ffffff);
  }

  .footer .social-icon {
    display: none;
  }

  .mission-vision {
    margin-top: 0px !important;
    flex-direction: column;
  }

  .mission-vision div:nth-child(2) span:nth-child(2),
  .div-Vision div:nth-child(1) span:nth-child(2) {
    height: -webkit-fill-available !important;
    width: -webkit-fill-available !important;
  }

  .our-vision-text {
    font-size: 12px;
  }

  .our-mission-text {
    font-size: 12px;
  }

  .mission-vision-div {
    height: 100%;
  }

  .team-carousel .team-card .img {
    height: 200px;
    display: flex;
    justify-content: center !important;
  }

  .our-team-card {
    padding: 0px 10px;
  }

  .div-main {
    display: block !important;
  }

  .learnMore {
    width: 120px !important;
  }

  .div-with-mainContent {
    margin: 0px !important;
    width: 100% !important;
    /* margin: 0px 16px !important; */
  }

  .mainContentDesc {
    height: 125px !important
  }

  .div-with-mainContent p span {
    width: -webkit-fill-available;
    padding: 0px 1px;
  }

  .div-with-mainContent p {
    font-family: Montserrat !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: -0.02em !important;
    text-align: left !important;
  }

  .div-with-mainContent>span {
    font-family: Montserrat !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    width: 100% !important;
    height: -webkit-fill-available !important;
  }

  .div-main-stat {
    margin: 0px !important;
    width: 100% !important;
    max-width: 100vw !important;
    /* margin: 12px !important; */
    border-radius: 12px;
  }

  .div-sub-main-stat {
    /* margin: 0px !important; */
    padding: 16px 0px;
    margin-bottom: 40px;
    width: 100% !important;
    height: auto !important;
  }

  .div-sub-main-stat div {
    margin: 0px !important;
    width: 50% !important;
  }

  .div-aboutUs-main {
    display: block !important;
    margin-top: 0px !important;
    margin-bottom: 40px !important;
    width: 100% !important;
  }

  .div-aboutUs-main img {
    margin: 0px !important;
    width: 100% !important;
  }

  .div-aboutUs-main div {
    margin: 0px !important;
    width: 100% !important;
  }

  .div-aboutUs-main div span {
    margin: 0px !important;
    width: 100% !important;
  }

  .div-Mission {
    display: block !important;
    margin: 0px !important;
    width: 100% !important;
  }

  .div-Mission img {
    margin: 0px !important;
    width: 100% !important;
  }

  .div-Mission div {
    margin-bottom: 40px !important;
    width: 100% !important;
    height: 297px !important;
  }

  .div-Mission div span {
    margin: 0px !important;
    width: 100% !important;
  }

  .div-Vision {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    margin-top: 0px !important;
    margin-bottom: 40px !important;
    width: 100% !important;
  }

  .div-Vision img {
    margin: 0px !important;
    width: 100% !important;
  }

  .div-Vision div {
    margin: 0px !important;
    width: 100% !important;
    height: 297px !important;

  }

  .div-Vision div span {
    margin: 0px !important;
    width: 100% !important;
  }

  .div-team {
    margin: 0px !important;
    width: 100% !important;
  }

  .div-team div {
    margin: 0px !important;
    width: 100% !important;
    padding-bottom: 20px;
  }

  .team-card .img img {
    max-width: 100%;
  }

  .team-carousel .team-card {
    margin: 0% !important;
    height: -webkit-fill-available;
  }

  .wrapper .team-carousel {
    grid-auto-columns: 100% !important;
  }

  .wrapper i {
    display: none;
  }

  .home-page-arrow {
    cursor: pointer;
    width: 35px;
  }
}
