.promo-timer {
    font-size: 20px;
    font-weight: 500;
}
.main-promotion-timer-template-one {
    display: flex;
    height: 41px;
    padding: 10px 13px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: var(--Primary-50, #FDE8EE);
    box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.10);
    
}

.promo-timer-template-one {
    color: var(--Primary-900, #EA1E51);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.promo-heading {
    font-size: 16px;
    margin-bottom:4px
}
.main-promotion-timer-template-three {
    display: flex;
}
.sub-promotion-timer-template-three {
    display: flex;
    flex-direction: column;
}
.promo-timer-template-three {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background-color: #EA1E51;
    /* margin-right: 20px; */
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 3.5px;
}
.main-promotion-timer-template-four {
    display: flex;
    gap: 25px;
}
.sub-promotion-timer-template-four {
    display: flex;
    align-items: baseline;
}
.promo-timer-template-four {
    color: #1A1A1A;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.promo-timer-template-four-text { 
    color: #1A1A1A;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.5px;
    opacity: 0.2;

}
.main-promotion-timer-template-five {
    display: flex;
    gap: 10px;
}
.sub-promotion-timer-template-five {
    display: flex;
}
.promo-timer-template-five {
    display: flex;
    width: 47.054px;
    height: 83.875px;
    padding: 26px 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background: #1A1A1A;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.10);
    color: #FFF;
    font-family: Montserrat;
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 10px;
}
.partition {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 90px;
    margin-right: 6px;
  }
  
  .circle {
    width: 10px;
    height: 10px;
    background-color: lightgrey;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .circle:last-child {
    margin-bottom: 0; /* Remove margin from the last circle */
  }
  .partition-template-three {
    display: flex;
    flex-direction: column;
    max-height: 90px;
    padding: 13px;
  }
  
  .circle-template-three {
    width: 7px;
    height: 7px;
    background-color: #EA1E51;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
@media (max-width:1720px) {
    .promo-timer {
        font-size: 18px;
        font-weight: 500;
    }
    .promo-heading {
        font-size: 12px;
        margin-bottom:4px
    }
    .promo-timer-template-five { 
        width: 37.054px;
        height: 73.875px;
        font-size: 35px;
    }
}
@media (max-width:445px) {
    .promo-timer {
        font-size: 16px;
        font-weight: 500;
    }
    .promo-heading {
        font-size: 10px;
        margin-bottom:4px
    }
    .main-promotion-timer-template-one {
        height: 31px;
        padding: 10px 13px;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.10);
        
    }
    .promo-timer-template-one {
        font-size: 10px;
    }
    .promo-timer-template-three {
        height: 35px;
        width: 35px;
        margin-right: 1px;
        font-size: 16px;

    }
    .promo-timer-template-four {
        font-size: 18px;
    }
    .promo-timer-template-four-text {
        font-size: 10px;
    }
    .main-promotion-timer-template-four {
        gap:10px
    }
    .promo-timer-template-five { 
        width: 27.054px;
        height: 50.875px;
        font-size: 20px;
        margin-right: 5px;
    }
    .partition {
        max-height: 50px;
    }
    .circle {
        width: 5px;
        height: 5px;
    }
    .partition-template-three {
        padding: 8px;
    }
    .circle-template-three {
        width: 5px;
        height: 5px;
        margin-right: 3px;
      }

}