* {
  @apply 
    [&::-webkit-scrollbar]:tw-w-2
    [&::-webkit-scrollbar-track]:tw-rounded-full
    [&::-webkit-scrollbar-track]:tw-bg-gray-100
    [&::-webkit-scrollbar-thumb]:tw-rounded-full
    [&::-webkit-scrollbar-thumb]:tw-bg-gray-300
    dark:[&::-webkit-scrollbar-track]:tw-bg-neutral-700
    dark:[&::-webkit-scrollbar-thumb]:tw-bg-neutral-500;
}

// Section orders
#home-page { 
  @apply 
    tw-flex
    tw-flex-col;
}
section#ticker_tape { order: var(--ticker-tape-order) }
section#header { order: var(--header-order) }
section#hero_banner { order: var(--hero-banner-order) }
section#brands { order: var(--brands-order) }
section#banner { order: var(--banner-order) }
section#new_arrival { order: var(--new-arrival-order) }
section#featured_products { order: var(--featured-products-order) }
section#hot_sellers { order: var(--hot-sellers-order) }
section#footer { order: var(--footer-order) }
